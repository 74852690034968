.app-error {
    @include themify() {
        color: themed($app-error\color);
    }

    &__columns {
        display: flex;
    }

    &__link-column {
        margin-right: 35px;
    }

    &__code {
        font-size: 160px;
        line-height: 1;
        margin: 0 0 20px;
    }

    &__icon {
        width: 107px;
        height: 140px;
        margin-top: 5px;
        margin-bottom: 34px;
        fill: currentColor;
    }

    &__message {
        font-size: 16px;
        margin: 0;
        padding-left: 10px;

        @include themify() {
            color: themed($app-error__message\color);
        }
    }

    &__return {
        font-size: 16px;

        @include themify() {
            color: themed($app-error__return\color);
        }
    }

    &__return-icon {
        fill: currentColor;
        margin-right: 9px;
    }
}
