.time-widget {
    height: 100%;
    display: flex;
    align-items: center;

    &__value {
        font-size: 11px;
        line-height: 1.1;
        font-weight: $font--normal;
        font-family: $font-stack-extra;

        @include themify() {
            color: themed($time-widget\color);
        }
    }
}
