$tabs-height: 40px;
$chart-bottom-gap-lg: 36px;
$tools-height: 33px;

.chart-widget {
    position: relative;
    height: 100%;

    &--with-tools {
        @include above($lg) {
            padding-left: 52px;
        }
    }

    &__tabs {
        height: $tabs-height;

        @include above($lg) {
            margin-bottom: 20px;
        }

        @include themify() {
            background-color: themed($chart-widget__tabs\bg-color);
        }
    }

    &__tabs-nav {
        flex: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: $tabs-height;
        width: 23px;

        @include themify() {
            color: themed($chart-widget__tabs-nav\color);
            background-color: themed($chart-widget__tabs-nav\bg-color);
            border-color: themed($chart-widget__tabs-nav\border-color);
        }

        &--left {
            border-right: 1px solid;
            left: 0;
        }

        &--right {
            border-left: 1px solid;
            right: 0;
        }

        &.slick-disabled > svg {
            opacity: 0.25;
        }
    }

    &__tabs-nav-icon {
        fill: currentColor;
    }

    &__tabs-list {
        display: flex;
        white-space: nowrap;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__tabs-slider {
        margin: 0;
        padding: 0;
        display: flex;

        .slick-list {
            padding: 0 !important;
        }
    }

    &__tabs-item {
        flex-shrink: 0;
        vertical-align: top;
    }

    &__tabs-name {
        font-size: 13px;
        font-weight: $font--semi-bold;
        letter-spacing: -0.039px;
        display: flex;
        align-items: center;
        line-height: 1;
        transition: color 0.2s ease-in, background-color 0.2s ease-in;
        height: $tabs-height;
        padding-left: 10px;
        padding-right: 10px;

        &.is-active {
            border-right: 1px solid;
            display: flex;
            align-items: center;
        }

        @include above($lg) {
            font-size: 16px;
            letter-spacing: 0.14px;
            padding-left: 15px;
            padding-right: 15px;
        }

        @include themify() {
            color: themed($chart-widget__tabs-name\color);
            background-color: themed($chart-widget__tabs-name\bg-color);

            &:not(.is-active):hover {
                color: themed($chart-widget__tabs-name--hover\color);
            }

            &.is-active {
                color: themed($chart-widget__tabs-name--active\color);
                background-color: themed($chart-widget__tabs-name--active\bg-color);
                border-right-color: themed($chart-widget__tabs-name--active\border-color);
            }
        }
    }

    &__tabs-close {
        margin-right: 10px;
        margin-top: 2px;
        width: 10px;
        height: 10px;

        @include themify() {
            fill: themed($chart-widget__tabs-close\fill);
        }
    }

    &__tabs-close-icon {
        vertical-align: top;
    }

    &__tabs-dropdown-trigger {
        color: inherit;
    }

    &__tabs-dropdown-trigger-icon {
        margin-left: 8px;
        fill: currentColor;
    }

    &__new-tab {
        display: flex;
        align-items: center;
        height: $tabs-height;
        padding-left: 5px;
        padding-right: 5px;
    }

    &__new-tab-inner {
        width: 28px;
        height: 28px;
        border-radius: 50%;

        @include themify() {
            background-color: themed($chart-widget__new-tab\bg-color);
        }
    }

    &__new-tab-icon-wrap {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__new-tab-icon {
        vertical-align: middle;

        @include themify() {
            fill: themed($chart-widget__new-tab\fill);
        }
    }

    &__no-tabs-label {
        display: inline-block;
        vertical-align: top;
        line-height: $tabs-height;
        padding-left: 8px;
        padding-right: 8px;
    }

    &__tools {
        padding: 5px 8px 0;
        display: flex;
        align-items: center;

        @include above($lg) {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            flex-direction: column;
            border-right: 1px solid;
            padding: 5px 8px 34px;
        }

        @include themify() {
            border-color: themed($chart-widget__tools\border-color);
        }
    }

    &__tools-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;

        @include above($lg) {
            flex-direction: column;
        }
    }

    &__tools-item {
        & + & {
            margin-left: 16px;

            @include above($lg) {
                margin-left: 0;
                margin-top: 30px;
            }
        }
    }

    &__tool {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 4px;
        transition: color 0.2s ease-in, background-color 0.2s ease-in;

        @include above($lg) {
            width: 35px;
            height: 35px;
        }

        @include tool-colored();
    }

    &__tool-icon {
        fill: currentColor;
        width: 13px;
        height: 13px;

        @include above($lg) {
            width: 19px;
            height: 19px;
        }
    }

    &__tool-text {
        font-family: $font-stack-extra;
        font-size: 16px;
        font-weight: $font--light;
        line-height: 1;

        @include above($lg) {
            font-weight: $font--normal;
        }
    }

    &__tools-drag {
        width: 20px;
        height: 4px;
        margin-left: auto;

        @include above($lg) {
            margin-left: 0;
            margin-top: auto;
            width: 25px;
            height: 5px;
        }

        @include themify() {
            fill: themed($chart-widget__tools-drag\fill);
        }
    }

    &__tools-drag-icon {
        width: 20px;
        height: 4px;
        vertical-align: top;

        @include above($lg) {
            width: 25px;
            height: 5px;
        }
    }

    &__dropdown {
        position: relative;
    }

    &__dropdown-content {
        border-radius: 4px;
        z-index: 1;

        @include themify() {
            background-color: themed($chart-widget__dropdown-content\bg-color);
        }

        &::after {
            content: '';
            position: absolute;
        }

        .chart-widget__dropdown--tools & {
            top: 50%;
            left: calc(100% + 16px);
            transform: translateY(-50%);

            &::after {
                border-right: 6px solid;
                border-bottom: 6px solid transparent;
                border-top: 6px solid transparent;
                top: 50%;
                transform: translateY(-50%);
                right: 100%;

                @include themify() {
                    border-right-color: themed($chart-widget__dropdown-content\bg-color);
                }
            }
        }

        &--tabs {
            &::after {
                border-right: 6px solid transparent;
                border-left: 6px solid transparent;
                border-bottom: 6px solid transparent;
                bottom: 100%;
                transform: translateX(-50%);
                left: 50%;

                @include themify() {
                    border-bottom-color: themed($chart-widget__dropdown-content\bg-color);
                }
            }
        }
    }

    &__dropdown-btn {
        @include themify() {
            color: themed($chart-widget__dropdown-btn\color);

            &:hover {
                color: themed($chart-widget__dropdown-btn--hover\color);
                background-color: themed($chart-widget__dropdown-btn--hover\bg-color);
            }

            &.is-active {
                color: themed($chart-widget__dropdown-btn--active\color);
                background-color: themed($chart-widget__dropdown-btn--hover\bg-color);
            }
        }
    }

    &__timeframe-dropdown {
        min-width: 185px;
        padding: 7px;
    }

    &__timeframe-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }

    &__timeframe-btn {
        font-family: $font-stack-extra;
        font-size: 16px;
        font-weight: $font--light;
        line-height: 1;
        border-radius: 4px;
        vertical-align: top;
        padding: 7px 5px 5px;

        @include above($lg) {
            font-weight: $font--normal;
        }
    }

    &__tabs-dropdown-list-wrap {
        height: 210px;
    }

    &__tabs-dropdown-list {
        list-style: none;
        padding: 0;
        padding-top: 2px;
        margin: 0;
        max-height: 210px;
    }

    &__tabs-dropdown-btn {
        font-size: 13px;
        font-weight: $font--semi-bold;
        letter-spacing: -0.039px;
        vertical-align: top;
        width: 100%;
        padding: 7px 10px 6px 20px;
        text-align: left;

        @include above($lg) {
            font-size: 16px;
            letter-spacing: 0.14px;
        }
    }

    &__search {
        padding: 10px 10px 5px;
        width: 130px;
    }

    &__search-input {
        display: block;
        border: 1px solid;
        border-radius: 4px;
        font-family: $font-stack-base;
        font-size: 13px;
        font-weight: $font--semi-bold;
        letter-spacing: -0.039px;
        height: 28px;
        line-height: 1;
        padding: 6px 10px 5px;
        text-align: left;
        width: 100%;
        margin: 0;
        position: relative;
        z-index: 1;

        @include above($lg) {
            font-size: 16px;
            letter-spacing: 0.14px;
        }

        @include themify() {
            color: themed($chart-widget__search-input\color);
            background-color: themed($chart-widget__search-input\bg-color);
            border-color: themed($chart-widget__search-input\border-color);
        }

        @include placeholder() {
            font-size: 13px;
            font-weight: $font--light;

            @include themify() {
                color: themed($chart-widget__search-input-placeholder\color);
            }
        }
    }

    &__chart-wrap {
        height: calc(100% - #{$tabs-height} - #{$tools-height});

        @include above($lg) {
            height: calc(100% - #{$tabs-height} - #{$chart-bottom-gap-lg});
        }

        position: relative;

        .ecn-react-chart {
            & > div,
            svg {
                z-index: 0 !important;
            }
        }
    }

    &__chart-placeholder {
        height: calc(100% - #{$tabs-height});
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__chart-placeholder-icon {
        width: 20%;
        height: auto;

        @include themify() {
            fill: rgba(themed($chart-widget__chart-placeholder\fill), 0.7);
        }
    }

    &__empty-placeholder-icon {
        width: 20%;
        height: auto;

        @include themify() {
            fill: rgba(themed($chart-widget__chart-placeholder\fill), 0.7);
        }
    }

    &__chart-preloader {
        height: calc(100% - #{$tabs-height});
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__not-found {
        padding: 2px 10px 10px;
    }
}
