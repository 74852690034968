.lang-switcher {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    &--main-menu {
        width: 100%;

        &:hover {
            @include themify() {
                background-color: themed($main-menu__link--hover\bg-color);
            }
        }
    }

    @include themify() {
        color: themed($lang-switcher\color);
    }

    &__current {
        font-size: 16px;
        color: inherit;
    }

    .lang-switcher--main-menu &__current {
        width: 100%;
    }

    &__current-icon {
        fill: currentColor;
        margin-left: 10px;
        height: 8px;
        width: 4px;
    }

    &__dropdown {
        top: 50%;
        left: calc(100% + 30px);
        transform: translateY(-50%);
        border-radius: 4px;

        @include themify() {
            background-color: themed($lang-switcher__dropdown\bg-color);
        }

        &::after {
            content: '';
            position: absolute;
            border-right: 6px solid;
            border-bottom: 6px solid transparent;
            border-top: 6px solid transparent;
            top: 50%;
            transform: translateY(-50%);
            right: 100%;

            @include themify() {
                border-right-color: themed($lang-switcher__dropdown\bg-color);
            }
        }
    }

    .lang-switcher--main-menu &__dropdown {
        left: calc(100% - 150px);
        margin-top: 2px;
    }

    .lang-switcher--main-menu:hover &__dropdown {
        background-color: transparent;

        &::after {
            border-right-color: transparent;
        }
    }

    &__dropdown-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__btn {
        font-weight: $font--semi-bold;
        line-height: 1;
        padding: 6px 16px 8px 16px;
        transition: color 0.2s ease-in, background 0.2s ease-in;
        color: inherit;

        &:hover {
            @include themify() {
                background-color: themed($lang-switcher__btn--hover\bg-color);
                color: themed($lang-switcher__btn--hover\color);
            }
        }
    }

    .lang-switcher--main-menu &__btn {
        font-size: 16px;
    }

    .lang-switcher--main-menu:hover &__btn {
        background-color: transparent;
    }

    &__current-text {
        transition: $transition--default;
    }
}
