.fullscreen {
    &-button {
        @include tool();

        &__icon {
            width: 10px;
            height: 10px;
            fill: inherit;
        }
    }

    &-enabled {
        &.page--light {
            &:not(:root):fullscreen,
            &:not(:root):fullscreen::backdrop {
                background: $color--white;
            }
        }

        &.page--dark {
            &:not(:root):fullscreen,
            &:not(:root):fullscreen::backdrop {
                background: $color--bunker;
            }
        }
    }
}
