.Toastify {
    white-space: pre-line;

    &__toast {
        &--info {
            background: $color--cello;
        }

        &--success {
            background: $color--salem;
        }

        &--error {
            background: $color--cinnabar;
        }

        font-family: $font-stack-base;
        font-weight: $font--normal;
        color: $color--white-smoke;
    }
}
