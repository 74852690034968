.user {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    &__inner {
        &--long {
            display: none;
            @include above(960px) {
                display: block;
            }
        }

        &--short {
            display: block;
            @include above(960px) {
                display: none;
            }

            padding: 5px;
            border-radius: 4px;
            @include themify() {
                background-color: themed($user__inner--short\bg-color);
            }
        }
    }

    &__self {
        font-size: 16px;
        font-weight: $font--semi-bold;
        letter-spacing: 0.13px;
        cursor: pointer;

        @include above(960px) {
            cursor: unset;
            @include themify() {
                color: themed($user__self\color);
            }
        }
    }

    &__health {
        display: block;
        width: 22px;
        position: relative;

        &-indicator {
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 6px;
            height: 6px;
            border-radius: 50%;

            @include themify() {
                background-color: themed($user__connection\bg-color);
            }

            &--normal {
                @include themify() {
                    background-color: themed($user__connection--normal\bg-color);
                }
            }

            &--warning {
                @include themify() {
                    background-color: themed($user__connection--warn\bg-color);
                }
            }

            &--problem {
                @include themify() {
                    background-color: themed($user__connection--problem\bg-color);
                }
            }
        }
    }
}
