.login-form {
    &__inner {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0;
    }

    &__row + &__row {
        margin-top: 20px;
    }

    &__btn {
        border-radius: 4px;
        width: 350px;
        height: 48px;
        position: relative;
        font-size: 22px;
        line-height: 1;

        @include themify() {
            color: themed($login-form__btn\color);
        }

        &--demo {
            @include themify() {
                background: themed($login-form__btn--demo\bg-color);
            }
        }

        &--submit {
            text-transform: uppercase;

            @include themify() {
                background: themed($login-form__btn--submit\bg-color);
            }
        }

        &[disabled] {
            @include themify() {
                background: themed($login-form__btn--disabled\bg-color);
                color: themed($login-form__btn--disabled\color);
            }
        }
    }

    &__grecaptcha-note {
        font-size: 16px;
        margin-top: 20px;

        @include themify() {
            color: themed($login-form__grecaptcha-note\color);
        }
    }

    &__grecaptcha-note-link {
        @include themify() {
            color: themed($login-form__grecaptcha-note-link\color);
        }
    }

    &__footer {
        margin-top: 50px;
    }
}
