.financial-operations-form {
    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__input--half {
        width: calc(50% - 10px);
    }

    &__title {
        margin-top: 22px;
        height: 29px;
        @include themify() {
            color: themed($financial-operations-form__title\color);
        }
    }

    &__submit-btn {
        width: 100%;
        padding: 10px 34px;
        height: 41px;
        border-radius: 4px;
        font-size: 15px;
        line-height: 1.4;
        transition: $transition--default;

        @include themify() {
            color: themed($financial-operations-form__submit-btn\color);
            background-color: themed($financial-operations-form__submit-btn\bg-color);
        }

        &:hover {
            @include themify() {
                background-color: themed($financial-operations-form__submit-btn--hover\bg-color);
            }
        }
    }

    &__footer {
        margin-top: 25px;
    }
}
