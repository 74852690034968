.date-time {
    .react-datetime-picker {
        width: 100%;
        font-family: $font-stack-extra;
        font-size: 15px;
        line-height: 18px;

        &__wrapper {
            min-height: 25px;

            @include themify() {
                border-radius: 3px;
                background-color: themed($symbols-widget__details-volume-input\bg-color);
                border-color: themed($symbols-widget__details-volume-input\border-color);
            }
        }

        &__inputGroup {
            display: flex;
            justify-content: flex-end;
            align-items: baseline;

            .react-datetime-picker__inputGroup__divider,
            input {
                @include themify() {
                    color: themed($date-time--color\color);
                }
            }
        }

        &__calendar-button {
            order: -1;
            padding-left: 9px;
        }

        &__button__icon {
            width: 14px;
        }

        &__button {
            .react-datetime-picker__button__icon,
            &:enabled:hover .react-datetime-picker__button__icon,
            &:enabled:focus .react-datetime-picker__button__icon {
                @include themify() {
                    stroke: themed($date-time--color\color);
                }
            }
        }

        &__clear-button {
            padding-left: 2px;
        }

        &__clock {
            display: none;
        }

        &__calendar--open {
            z-index: 5;
        }

        &__inputGroup__input:invalid {
            background: transparent;
        }
    }

    .react-clock {
        display: none;
    }

    &__calendar-icon {
        @include themify() {
            fill: themed($date-time--color\color);
        }
    }
}
