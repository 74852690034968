$transition-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);

.tumbler {
    position: relative;
    z-index: 0;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    color: inherit;

    &__input {
        position: absolute;
        width: 1px;
        height: 1px;
        border: 0;
        padding: 0;
        margin: -1px;
        clip: rect(0 0 0 0);
        overflow: hidden;
    }

    &__input + &__label {
        position: relative;
        display: inline-block;
        width: 48px;
        height: 22px;
        vertical-align: top;
        border: 1px solid currentColor;
        background: transparent;
        border-radius: 100px;
        transition: border 150ms $transition-function;
        cursor: pointer;
    }

    &__label::before {
        position: relative;
        content: '';
        top: 2px;
        left: 3px;
        z-index: 1;
        display: inline-block;
        width: 16px;
        height: 16px;
        background: currentColor;
        border-radius: 100px;
        transition: background 150ms $transition-function, transform 150ms $transition-function;
        transform: translateX(-1px);
    }

    &__label::after {
        position: absolute;
        content: attr(customattr);
        top: 2px;
        right: 2px;
        z-index: 1;
    }

    &__input:checked + &__label::after {
        right: unset;
        left: 2px;
    }

    &__input:checked + &__label::before {
        background: currentColor;
        transform: translateX(25px);
    }

    &__input:disabled + &__label {
        cursor: default;
        pointer-events: none;
    }
}
