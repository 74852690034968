.modal {
    position: absolute;
    top: 0;
    left: 0;

    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        overflow: auto;
        transform: translateZ(0);

        @include themify() {
            background-color: rgba(themed($auth-error-modal__overlay\bg-color), 0.4);
        }
    }
}
