.dropdown {
    display: inline-block;

    &__content {
        display: none;
        position: absolute;
    }

    &--active &__content {
        display: block;
    }
}
