.main-logo {
    @include themify() {
        color: themed($main-logo\color);
    }

    &__link {
        display: block;
        color: inherit;
    }

    &__icon {
        height: 41px;
        width: 105px;
        fill: currentColor;
    }
}
