.hint {
    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        color: $color--white;
        background: $color--cello;

        &:hover,
        &:focus,
        &:active {
            background: $color--madison;
        }
    }

    &__icon {
        fill: currentColor;
    }

    &__tooltip {
        letter-spacing: initial;
        max-width: 300px;

        &.place-top::after {
            border-top-color: $color--cello !important;
            border-top-style: solid !important;
            border-top-width: 10px !important;
        }
    }
}
