$spacer: 7px;
$spacer-lg: 9px;

$up-area-height: calc(75vh - #{$page-header-height} - #{$spacer} * 2); // spacer is padding-top
$down-area-height: calc(25vh - (#{$spacer} * 2)); // spacers are margin-top and padding-bottom

$up-area-height-lg: calc(75vh - #{$page-header-height} - #{$spacer-lg} * 2);
$down-area-height-lg: calc(25vh - (#{$spacer-lg} * 2));

$up-area-height-portrait: calc(50vh - #{$page-header-height} - #{$spacer} * 2);
$down-area-height-portrait: calc(50vh - (#{$spacer} * 2));

$up-area-height-portrait-lg: calc(50vh - #{$page-header-height} - #{$spacer-lg} * 2);
$down-area-height-portrait-lg: calc(50vh - (#{$spacer-lg} * 2));

.dashboard-screen {
    &__row {
        display: flex;

        & + & {
            margin-top: $spacer;

            @include above($lg) {
                margin-top: $spacer-lg;
            }
        }
    }

    &__chart {
        width: calc(62% - #{$spacer});
        height: $up-area-height;

        @include above($lg) {
            height: $up-area-height-lg;
        }

        @include portrait() {
            height: $up-area-height-portrait;
        }

        @include portraitAbove($lg) {
            height: $up-area-height-portrait-lg;
        }

        @include above($lg) {
            width: calc(67% - #{$spacer-lg});
        }
    }

    &__symbols {
        width: 38%;
        margin-left: $spacer;
        height: $up-area-height;

        @include above($lg) {
            height: $up-area-height-lg;
        }

        @include portrait() {
            height: $up-area-height-portrait;
        }

        @include portraitAbove($lg) {
            height: $up-area-height-portrait-lg;
        }

        @include above($lg) {
            margin-left: $spacer-lg;
            width: 33%;
        }
    }

    &__operations {
        flex: none;
        width: 100%;
        height: $down-area-height;

        @include above($lg) {
            height: $down-area-height-lg;
        }

        @include portrait() {
            height: $down-area-height-portrait;
        }

        @include portraitAbove($lg) {
            height: $down-area-height-portrait-lg;
        }
    }
}
