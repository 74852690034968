$width50percent: calc(50% - 2px);
$inputMinHeight: 25px;

.order-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 5px 0 0;

    @include above($lg) {
        padding-top: 6px;
    }

    &__header {
        height: $inputMinHeight;
        display: flex;
        align-items: flex-end;
        margin-top: 0;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;

        @include above($lg) {
            height: 33px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .order-form--on-position-edit-mode & {
            justify-content: start;
            align-items: baseline;
        }
    }

    &__switch {
        padding: 0 10px 5px;

        @include above($lg) {
            padding: 0 15px 5px;
        }
    }

    &__title {
        flex-shrink: 0;
        font-size: 18px;
        font-weight: $font--semi-bold;
        line-height: 20px;
        letter-spacing: 0.14px;

        @include themify() {
            color: themed($symbols-widget__details-title\color);
        }

        @include above($lg) {
            font-size: 23px;
            line-height: 30px;
        }
    }

    &__favorites {
        align-self: center;
        cursor: pointer;
        margin-left: 17px;
        width: 16px;
        height: 16px;

        @include below(1024px) {
            margin-left: 6px;
        }

        @include below(960px) {
            margin-left: 3px;
            padding-top: 3px;
            svg {
                width: 16px;
                height: 16px;
            }
        }

        &-icon {
            vertical-align: baseline;

            @include themify() {
                fill: themed($symbols-widget__details-star\fill);
            }
        }

        &.is-active &-icon {
            @include themify() {
                fill: themed($symbols-widget__details-star--active\fill);
            }
        }
    }

    &__chart-btn {
        align-self: center;
        cursor: pointer;
        margin-left: 7px;
        margin-right: 6px;
        width: 16px;
        height: 16px;

        @include below(960px) {
            padding-top: 3px;
            margin-left: 0;
            svg {
                width: 16px;
                height: 16px;
            }
        }

        @include themify() {
            color: themed($symbols-widget__details-chart-icon\fill);
        }

        &.is-active {
            @include themify() {
                color: themed($symbols-widget__details-chart-icon--active\fill);
            }
        }

        &-icon {
            vertical-align: baseline;
            fill: currentColor;
        }

        .order-form--on-position-edit-mode & {
            margin-left: 17px;
            align-self: unset;
        }
    }

    &__price-info {
        display: flex;
        flex-shrink: 1;
        justify-content: space-between;
        line-height: 1;
        margin-left: auto;

        &-item {
            display: flex;
            align-items: baseline;

            & + & {
                margin-left: 15px;
            }
        }

        &-label {
            font-family: $font-stack-base;
            font-size: 11px;
            line-height: 13px;
            font-weight: $font--light;
            letter-spacing: 0.14px;
            margin-right: 3px;

            @include above($lg) {
                font-size: 13px;
                line-height: 25px;
            }

            @include themify() {
                color: themed($symbols-widget__details-price-info-label\color);
            }
        }

        &-value {
            font-family: $font-stack-extra;
            font-weight: $font--normal;
            letter-spacing: 0.6px;
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 0;

            @include above($lg) {
                font-size: 16px;
                line-height: 19px;
            }

            @include themify() {
                color: themed($symbols-widget__details-price-info-value\color);
            }

            &--colorable.is-up {
                @include themify() {
                    color: themed($symbols-widget__details-price-info-value--up\color);
                }
            }

            &--colorable.is-down {
                @include themify() {
                    color: themed($symbols-widget__details-price-info-value--down\color);
                }
            }
        }
    }

    &__body {
        font-family: $font-stack-base;
        font-weight: $font--book;
        font-size: 13px;
        line-height: 19px;
        height: calc(100% - 105px);

        @include themify() {
            color: themed($symbols-widget__details-body\color);
        }

        &-inner {
            display: flex;
            flex-direction: column;
            padding: 8px 10px;

            @include above($lg) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &-section {
            & + &,
            & + .order-form__body-row {
                margin-top: 5px;

                @include above($lg) {
                    margin-top: 7px;
                }
            }
        }

        /* stylelint-disable no-descending-specificity */
        &-row {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;
            min-height: $inputMinHeight;

            & + &,
            & + .order-form__body-section {
                margin-top: 5px;

                @include above($lg) {
                    margin-top: 8px;
                }
            }
        }
    }

    &__footer {
        width: 100%;
        padding: 5px 10px 10px;

        @include above($lg) {
            padding-left: 15px;
            padding-right: 15px;
        }

        @include themify() {
            background-color: themed($page-area\bg-color);
        }
    }

    &__controlled-input {
        max-width: $width50percent;
    }

    &__actions {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: nowrap;
        margin-top: 9px;

        .order-form--on-position-edit-mode & {
            margin-top: 6px;
        }
    }

    &__action {
        flex-grow: 0;
        flex-shrink: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        height: 42px;
        padding: 8px;
        width: 100%;

        & + & {
            margin-left: 5px;
        }

        &--buy[disabled],
        &--sell[disabled] {
            cursor: not-allowed;

            @include themify() {
                color: themed($symbols-widget__details-action--disabled\color);
                background: themed($symbols-widget__details-action--disabled\bg-color);
            }
        }

        @include themify() {
            color: themed($symbols-widget__details-action\color);
            fill: themed($symbols-widget__details-action\color);

            &--buy {
                background: themed($symbols-widget__details-action--buy\bg-color);

                &:hover:enabled {
                    background: themed($symbols-widget__details-action--buy-hover\bg-color);
                }
            }

            &--sell {
                background: themed($symbols-widget__details-action--sell\bg-color);

                &:hover:enabled {
                    background: themed($symbols-widget__details-action--sell-hover\bg-color);
                }
            }
        }

        &-text {
            font-family: $font-stack-base;
            font-weight: $font--semi-bold;
            font-size: 15px;
            line-height: 20px;
        }
    }

    &__action-commission {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 9px;

        @include above(851px) {
            font-size: 12px;
        }

        &-row {
            width: 100%;
            display: flex;
            align-items: center;
            min-height: 14px;

            @include above(851px) {
                font-size: 12px;
                min-height: 19px;
            }
        }

        &-text {
            padding-right: 10px;
            margin-right: auto;
        }

        &-value {
            min-width: 36px;
            text-align: right;

            @include above(851px) {
                min-width: 40px;
            }

            @include above(960px) {
                min-width: 50px;
            }
        }
    }

    &__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 25px;

        &--solid &-text {
            font-weight: $font--semi-bold;
        }

        & .hint__button {
            margin-left: 4px;
        }

        .inactive & {
            opacity: 0.5;

            & .hint__button {
                cursor: default;
            }
        }

        &--footer {
            margin-bottom: 9px;
        }
    }

    &__decorated-value {
        font-family: $font-stack-extra;
        font-weight: $font--normal;
        letter-spacing: 0.6px;
        font-size: 15px;
        line-height: 18px;
        padding: 5px 9px;
        border-radius: 4px;
        text-align: right;

        @include themify() {
            color: themed($symbols-widget__decorated-value\color);
            background-color: themed($symbols-widget__decorated-value\bg-color);
        }

        .inactive & {
            opacity: 0.5;
        }

        &--fixed-size {
            height: $inputMinHeight;
            width: $width50percent;
        }
    }

    &__value-container {
        display: flex;
        align-items: center;
        height: 31px;
        line-height: 1.1;
    }

    &__volume {
        &-container {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
        }

        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            border: 1px solid;
            border-radius: $width50percent;
            padding: 0;
            margin: 0;
            cursor: pointer;

            &-icon {
                width: 8px;

                @include themify() {
                    fill: themed($symbols-widget__details-volume-input-btn\color);
                }
            }

            &--minus {
                margin-right: 6px;

                @include themify() {
                    background-color: themed($symbols-widget__details-volume-input-btn--minus\bg-color);
                    border-color: themed($symbols-widget__details-volume-input-btn--minus\bg-color);
                }

                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
            }
            &--plus {
                margin-left: 6px;

                @include themify() {
                    background-color: themed($symbols-widget__details-volume-input-btn--plus\bg-color);
                    border-color: themed($symbols-widget__details-volume-input-btn--plus\bg-color);
                }

                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
            }

            &--minus:hover,
            &--minus:focus,
            &--minus:active,
            &--plus:hover,
            &--plus:focus,
            &--plus:active {
                @include themify() {
                    border-color: themed($symbols-widget__details-volume-input-btn--active\border-color);
                }
            }

            &--minus[disabled],
            &--plus[disabled] {
                cursor: not-allowed;

                @include themify() {
                    color: themed($symbols-widget__details-action--disabled\color);
                    background-color: themed($symbols-widget__details-action--disabled\bg-color);
                    border-color: themed($symbols-widget__details-action--disabled\bg-color);
                }
            }
        }

        &-input {
            display: block;
            border: 1px solid;
            height: 31px;
            width: 47px;
            border-radius: 4px;
            font-family: $font-stack-extra;
            font-size: 16px;
            font-weight: $font--normal;
            letter-spacing: 0.6px;
            line-height: 1;
            padding: 6px 6px 5px;
            text-align: right;

            @include themify() {
                color: themed($symbols-widget__details-volume-input\color);
                background-color: themed($symbols-widget__details-volume-input\bg-color);
                border-color: themed($symbols-widget__details-volume-input--order\border-color);
                &:focus {
                    background-color: themed($symbols-widget__details-volume-input--focus\bg-color);
                }
            }

            @include placeholder() {
                font-weight: $font--light;

                @include themify() {
                    color: themed($symbols-widget__details-volume-input-placeholder\color);
                }
            }
        }
    }

    &__input {
        display: block;
        border: 1px solid;
        height: 31px;
        width: 100px;
        border-radius: 4px;
        font-family: $font-stack-extra;
        font-size: 16px;
        font-weight: $font--normal;
        letter-spacing: 0.6px;
        line-height: 1;
        padding: 6px 6px 5px;
        text-align: right;

        @include themify() {
            color: themed($symbols-widget__details-volume-input\color);
            background-color: themed($symbols-widget__details-volume-input\bg-color);
            border-color: themed($symbols-widget__details-volume-input--order\border-color);
            &:focus {
                background-color: themed($symbols-widget__details-volume-input--focus\bg-color);
            }
        }

        @include placeholder() {
            font-weight: $font--light;

            @include themify() {
                color: themed($symbols-widget__details-volume-input-placeholder\color);
            }
        }
    }

    &__insurance-tumbler {
        // overwrite default tumbler behavior
        color: $color--white;

        .tumbler__input + .tumbler__label {
            width: 48px;
            height: 20px;

            @include themify() {
                background-color: themed($symbols-widget__insurance-tumbler\bg-color);
                border: themed($symbols-widget__insurance-tumbler\bg-color);
            }
        }

        .tumbler__label::before {
            background: currentColor;
            width: 14.7px;
            height: 14.7px;
            top: 3px;
            transform: translateX(0);
        }

        .tumbler__label::after {
            top: 0;
            height: 20px;
            width: 24px;
            right: 5px;
            text-transform: uppercase;
            font-size: 8px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .tumbler__input:checked + .tumbler__label::after {
            left: 5px;
            justify-content: flex-start;
        }

        .tumbler__input:checked + .tumbler__label {
            @include themify() {
                background-color: themed($symbols-widget__insurance-tumbler--checked\bg-color);
                border: themed($symbols-widget__insurance-tumbler--checked\bg-color);
            }
        }

        .tumbler__input:checked + .tumbler__label::before {
            transform: translateX(26px);
        }
    }

    &__type-select {
        width: 100%;

        .select__control {
            @include themify() {
                background-color: themed($symbols-widget__details-volume-input\bg-color);
                border-color: themed($symbols-widget__details-volume-input\border-color);
            }

            &--menu-is-open {
                @include themify() {
                    border-color: themed($symbols-widget__details-volume-input--focus\border-color);
                }
            }
        }

        .select__value-container {
            margin-right: 5px;
            justify-content: flex-end;
            @include themify() {
                color: themed($symbols-widget__details-volume-input\color);
            }
        }

        .select__option {
            text-align: right;
        }

        .select__option,
        .select__menu-notice {
            padding-left: 7px;
            padding-right: 7px;
        }

        .select__indicators {
            padding: 2px 5px 0 2px;
        }

        .select__dropdown-indicator {
            position: relative;
            width: 6px;
            height: 4px;

            @include themify() {
                color: themed($symbols-widget__details-volume-input\color);
            }

            &::after {
                border-top: 5px solid currentColor;
                border-right: 3px solid transparent;
                border-left: 3px solid transparent;
            }
        }

        .inactive & {
            opacity: 0.5;
        }
    }

    .date-time,
    .select-wrap {
        width: $width50percent;
    }

    &__colored {
        &--red {
            color: $color--cinnabar;
        }

        &--green {
            color: $color--salem;
        }

        fill: currentColor;
    }
}
