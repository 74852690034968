.tooltip {
    padding: 6px !important;
    color: $color--white !important;
    background-color: $color--cello !important;
    font-family: $font-stack-base !important;
    font-weight: $font--light !important;
    font-size: 11px !important;
    line-height: 1.2 !important;
    opacity: 1 !important;

    &.place-top::after {
        border-top-color: $color--cello !important;
    }
    &.place-bottom::after {
        border-bottom-color: $color--cello !important;
    }
    &.place-left::after {
        border-left-color: $color--cello !important;
    }
    &.place-right::after {
        border-right-color: $color--cello !important;
    }
}
