.variation-group {
    width: 100%;
    display: block;
    overflow: hidden;
    padding: 3px;
    font-weight: $font--semi-bold;
    font-size: 12px;
    line-height: 16px;
    z-index: 3;
    border-radius: 6px;

    @include themify() {
        color: themed($variation-group\color);
        background-color: themed($variation-group\bg-color);
    }

    &__container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__list {
        position: relative;
        width: 100%;
        height: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    &__item {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        flex-shrink: 0;
        z-index: 2;
        cursor: pointer;
        transition: color 0.2s ease-out;
        min-height: 24px;
        font-size: 12px;
        line-height: 20px;
        border-radius: 6px;

        &--selected {
            @include themify() {
                color: themed($variation-group__item--selected\color);
            }
        }

        .variation-group--disabled & {
            cursor: default;
        }
    }

    &__item-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-weight: $font--normal;
    }

    &__item-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
    }

    &__item-icon {
        flex-shrink: 0;
        margin-right: 8px;
    }

    &__marker {
        position: absolute;
        padding: 0;
        top: 0;
        left: 0;
        z-index: 1;
        transition: transform 0.2s;
        min-height: 24px;
        border-radius: 6px;

        @include themify() {
            background-color: themed($variation-group__item--selected\bg-color);
        }
    }
}
