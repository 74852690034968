.select {
    position: relative;
    width: 100%;
    height: 31px;

    &--sm {
        height: 25px;
    }

    &--lg {
        height: 54px;
    }

    &__control {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        border: 1px solid;
        background-color: transparent; // TODO
        border-radius: 4px;
        cursor: pointer;
        outline: 0;
        transition: $transition--default;

        .select--sm & {
            height: 25px;
        }
        .select--lg & {
            height: 54px;
        }

        @include themify() {
            color: themed($select\color);
            border-color: themed($select\border-color);
            background-color: themed($select\bg-color);

            &:focus {
                border-color: themed($select--hover\border-color);
                background-color: themed($select--focus\bg-color);
            }
        }

        .select--error & {
            @include themify() {
                color: themed($select--error\color);
                background-color: themed($select--error\bg-color);
                border-color: themed($select--error\border-color);
            }
        }

        &:focus,
        &:hover,
        &--menu-is-open {
            outline: 0;

            @include themify() {
                border-color: themed($select--hover\border-color);
                background-color: themed($select--focus\bg-color);
            }

            .select--error & {
                @include themify() {
                    color: themed($select--error\color);
                    background-color: themed($select--error\bg-color);
                    border-color: themed($select--error\border-color);
                }
            }
        }

        &--is-disabled,
        &--is-disabled:hover {
            cursor: default;
        }
    }

    &__value-container {
        position: relative;
        display: flex;
        flex: 1 1 0;
        flex-wrap: wrap;
        align-items: center;
        padding: 6px 0 6px 12px;
        font-family: $font-stack-extra;
        font-size: 15px;
        line-height: 18px;
        font-weight: $font--normal;
        letter-spacing: 0.13px;
        overflow: hidden;

        @include themify() {
            color: themed($select\color);
        }

        .select--sm & {
            padding: 2px 0 2px 7px;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.02em;
        }

        .select--lg & {
            font-weight: $font--medium;
            font-size: 18px;
        }

        .select--error & {
            @include themify() {
                color: themed($select--error\color);
            }
        }
    }

    &__single-value {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: calc(100% - 8px);
        overflow: hidden;
        line-height: 21px;
        text-overflow: ellipsis;
        white-space: nowrap;

        .select--sm & {
            max-width: calc(100% - 3px);
        }
    }

    &__indicators {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        align-self: stretch;
        padding: 2px 12px 0;
    }

    &__indicator-separator {
        display: none;
    }

    &__loading-indicator {
        color: rgb(204, 204, 204);
        display: flex;
        align-self: center;
        font-size: 4px;
        line-height: 1;
        margin-right: 4px;
        text-align: center;
        vertical-align: middle;
        padding: 8px;
        transition: color 150ms ease 0s;

        & > span {
            display: inline-block;
            height: 1em;
            vertical-align: top;
            width: 1em;
            background-color: currentColor;
            border-radius: 1em;
            animation: 1s ease-in-out 0ms infinite normal none running select-loading-indicator;

            &:nth-child(2) {
                animation-delay: 160ms;
            }

            &:last-child {
                animation-delay: 320ms;
            }

            & + & {
                margin-left: 1em;
            }
        }

        @keyframes select-loading-indicator {
            0%,
            80%,
            100% {
                opacity: 0;
            }
            40% {
                opacity: 1;
            }
        }
    }

    &__dropdown-indicator {
        position: relative;
        width: 10px;
        height: 5px;
        background-color: transparent;
        transition: $transition--default;

        @include themify() {
            color: themed($select\control-color);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-top: 5px solid currentColor;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;

            .select__control--is-disabled & {
                opacity: 0.8;
            }
        }

        & > svg {
            display: none;
        }

        .select__control--menu-is-open & {
            transform: rotate(-180deg);
        }
    }

    &__placeholder {
        position: absolute;
        top: 50%;
        font-size: 16px;
        font-weight: $font--light;
        line-height: 19px;
        letter-spacing: 0.02em;
        transform: translateY(-50%);
        white-space: nowrap;

        @include themify() {
            color: themed($select\placeholder-color);
        }

        .select--sm & {
            font-size: 13px;
            line-height: 16px;
        }

        .select--lg & {
            font-size: 18px;
        }
    }

    &__menu {
        position: absolute;
        top: 100%;
        z-index: 1;
        width: 100%;
        margin: 2px 0;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.08);
        overflow: hidden;

        @include themify() {
            color: themed($select-dropdown\color);
            background-color: themed($select-dropdown\bg-color);
        }
    }

    &__menu-list {
        position: relative;
        max-height: 300px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .select--sm & {
            max-height: 95px;
        }
    }

    &__option,
    &__menu-notice {
        position: relative;
        display: block;
        width: 100%;
        padding: 2px 25px 6px 11px;
        cursor: pointer;
        user-select: none;
        font-size: inherit;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        .select--sm & {
            padding: 5px 5px 4px 7px;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.02em;
        }

        .select--lg & {
            padding: 12px 25px 11px 11px;
        }

        &--is-selected {
            @include themify() {
                color: themed($select-dropdown-item--selected\color);
                background-color: themed($select-dropdown-item--selected\bg-color);
            }
        }

        &--is-focused {
            outline: none;

            @include themify() {
                color: themed($select-dropdown-item--focus\color);
                background-color: themed($select-dropdown-item--focus\bg-color);
            }
        }
    }
}
