.unauthenticated-layout {
    padding-top: 60px;

    &__logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 60px;
        height: 48px;
        width: 140px;

        @include themify() {
            fill: themed($unauthenticated-layout__logo\fill);
        }
    }

    &__app-error {
        padding-top: 100px;
        display: flex;
        justify-content: center;
    }
}
