// =============================================================================
// Font Face
// =============================================================================

// $name - name for font-family
// $path - path to font without format
// $display - font-display property
// $weight - font-weight
// $style - font-style
// $exts - used font formats
@mixin font-face($name, $path, $display: auto, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
        eot: '?',
        svg: '#' + str-replace($name, ' ', '_')
    );

    $formats: (
        otf: 'opentype',
        ttf: 'truetype'
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
        font-display: $display;
    }
}

// =============================================================================
// Placeholder
// =============================================================================
@mixin placeholder {
    $placeholders: ':-webkit-input' ':-moz' '-ms-input';

    @each $placeholder in $placeholders {
        @if $placeholder == ':-moz' {
            &:#{$placeholder}-placeholder {
                opacity: 1;
                @content;
            }
        } @else {
            &:#{$placeholder}-placeholder {
                @content;
            }
        }
    }
}

// =============================================================================
// Media Query Breakpoints
// =============================================================================
@mixin below($width) {
    @media (max-width: $width - 1px) {
        @content;
    }
}

@mixin above($width) {
    @media (min-width: $width) {
        @content;
    }
}

@mixin between($minWidth, $maxWidth) {
    @media (min-width: $minWidth) and (max-width: $maxWidth) {
        @content;
    }
}

@mixin below($width) {
    @media (max-width: $width - 1px) {
        @content;
    }
}

@mixin aboveWidthAndHeight($width, $height) {
    @media (min-width: $width) and (min-height: $height) {
        @content;
    }
}

@mixin portrait() {
    @media (orientation: portrait) {
        @content;
    }
}

@mixin portraitAbove($width) {
    @media (orientation: portrait) and (min-width: $width) {
        @content;
    }
}

// =============================================================================
// Clearfix
// =============================================================================
@mixin clearfix {
    &::after {
        clear: both;
        content: '';
        display: table;
    }
}

// =============================================================================
// Overflow ellipsis
// =============================================================================
@mixin overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// =============================================================================
// Visually hidden
// =============================================================================
@mixin visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

// =============================================================================
// Tool
// =============================================================================
@mixin tool-colored(
    $color: $tool\color,
    $bgColor: $tool\bg-color,
    $colorHover: $tool--active\color,
    $bgColorHover: $tool--active\bg-color
) {
    transition: color 0.2s ease-in, background-color 0.2s ease-in;

    @include themify() {
        color: themed($color);
        fill: themed($color);
        background-color: themed($bgColor);
    }

    &.is-active,
    &:hover:enabled {
        @include themify() {
            color: themed($colorHover);
            fill: themed($colorHover);
            background-color: themed($bgColorHover);
        }
    }
}

@mixin tool(
    $width: 22px,
    $height: 22px,
    $borderRadius: 4px,
    $color: $tool\color,
    $bgColor: $tool\bg-color,
    $colorHover: $tool--active\color,
    $bgColorHover: $tool--active\bg-color
) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: $width;
    height: $height;
    border: 0;
    border-radius: $borderRadius;

    @include tool-colored($color, $bgColor, $colorHover, $bgColorHover);
}
