.state-account {
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;

    &__list {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        font-size: 13px;
        font-weight: $font--normal;
        line-height: 1.2;

        & + & {
            padding-left: 12px;
            @include above($lg) {
                padding-left: 30px;
            }
        }

        &--hidden {
            opacity: 0;
            z-index: -1;
            position: relative;
        }
    }

    &__item-description {
        margin-right: 5px;
        white-space: nowrap;

        @include themify() {
            color: themed($header-state__item-description\color);
        }
    }

    &__item-value {
        font-family: $font-stack-extra;
        font-size: 16px;
        font-weight: $font--medium;

        @include themify() {
            color: themed($header-state__item-value\color);
        }
    }

    &__dropdown-menu {
        position: absolute;

        &-list {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }

        &-item {
            padding: 0 8px;
            display: flex;
            justify-content: space-between;
        }

        &-button {
            @include tool();

            &--hidden {
                @include visually-hidden();
            }
        }

        &-content {
            position: absolute;
            padding: 8px 0;
            top: 30px;
            right: 0;
            border-radius: 4px;
            z-index: 10;

            @include themify() {
                background-color: themed($state-account-dropdown-content\bg-color);
            }

            box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
        }

        .dropdown__trigger {
            margin-left: 15px;
        }
    }
}
