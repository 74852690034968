.operation-widget {
    height: 100%;

    &__tabs {
        overflow: hidden;

        @include themify() {
            background-color: themed($operation-widget__tabs-list\bg-color);
        }
    }

    &__tabs-list {
        display: flex;
        overflow: hidden;
    }

    &__tabs-list-slider {
        margin: 0;
        padding: 0;
        display: flex;

        // especial padding for first tab if arrows is inactive
        .slick-list:first-child {
            .slick-slide:first-child {
                .operation-widget__tabs-btn {
                    padding-left: 24px;
                }
            }
        }
    }

    &__tabs-item {
        flex-shrink: 0;
        position: relative;

        &.is-active {
            @include themify() {
                background-color: themed($operation-widget__tabs-item--active\bg-color);
            }
        }

        &.has-notification {
            &::after {
                content: '';
                position: absolute;
                top: 3px;
                right: 3px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                @include themify() {
                    background-color: themed($operation-widget__tabs-item--has-notification\bg-color);
                }
            }
        }
    }

    &__tabs-wrap-btn {
        display: block;
    }

    /* stylelint-disable no-descending-specificity */
    &__tabs-btn {
        padding: 4px 10px 8px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.14px;
        transition: color 0.2s ease-in;

        @include themify() {
            color: themed($operation-widget__tabs-btn\color);
        }

        .operation-widget__tabs-item.is-active & {
            @include themify() {
                color: themed($operation-widget__tabs-btn--active\color);
            }

            &:hover {
                cursor: default;
            }
        }

        .operation-widget__tabs-item:not(.is-active):hover & {
            @include themify() {
                color: themed($operation-widget__tabs-item--not-active-hover\color);
            }
        }
    }
    /* stylelint-enable */

    &__tabs-nav {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 23px;

        @include themify() {
            color: themed($operation-widget__tabs-nav\color);
            background-color: themed($operation-widget__tabs-nav\bg-color);
            border-color: themed($operation-widget__tabs-nav\border-color);
        }

        &--left {
            border-right: 1px solid;
        }

        &--right {
            border-left: 1px solid;
        }

        &:disabled > svg {
            opacity: 0.25;
        }
    }

    &__groups-nav-icon {
        fill: currentColor;
    }

    &__table-wrap {
        height: calc(100% - 28px);

        &--orders,
        &--history,
        &--executions,
        &--positions,
        &--news,
        &--balances,
        &--bets,
        &--betsHistory {
            display: flex;
            flex-direction: column;
        }

        &--news .operation-widget__table-body .operation-widget__table-row:hover {
            cursor: pointer;
        }
    }

    &__table {
        height: 100%;
    }

    &__table-item {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
    }

    &__table-head {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
    }

    &__table-head-self {
        border-bottom: 1px solid;

        @include themify() {
            border-color: themed($operation-widget__table-head\border-color);
        }
    }

    &__table-head-cell {
        display: table-cell;
        padding-top: 7px;
        padding-bottom: 6px;
        font-family: $font-stack-base;
        font-weight: $font--normal;
        font-size: 11px;
        line-height: 1.4;
        letter-spacing: 0.4px;
        text-align: left;

        @include overflow-ellipsis;

        @include themify() {
            color: themed($operation-widget__table-head-cell\color);
        }

        padding-right: 10px;

        &:first-of-type {
            padding-left: 24px;
        }

        &:last-of-type {
            padding-right: 24px;
        }

        /* stylelint-disable no-descending-specificity */
        .operation-widget__table-wrap--positions & {
            text-align: right;

            &:first-of-type {
                text-align: left;
                width: 12%;
            }

            &:nth-of-type(2) {
                width: 7%;
            }

            &:nth-of-type(3) {
                width: 12%;
            }

            &:nth-of-type(4) {
                width: 7%;
            }

            &:nth-of-type(5) {
                width: 14%;
            }

            &:nth-of-type(6) {
                width: 7%;
            }

            &:nth-of-type(7) {
                width: 10%;
            }

            &:nth-of-type(8) {
                width: 7%;
            }

            &:nth-of-type(9) {
                width: 10%;
            }

            &:nth-of-type(10) {
                width: 7%;
            }

            &:last-of-type {
                width: auto;
            }
        }

        .operation-widget__table-wrap--orders &,
        .operation-widget__table-wrap--history & {
            text-align: right;

            &:first-of-type {
                text-align: left;
                width: 7%;
            }

            &:nth-of-type(2) {
                text-align: left;
                width: 10%;
            }

            &:nth-of-type(3) {
                width: 3%;
            }

            &:nth-of-type(4) {
                width: 8%;
            }

            &:nth-of-type(5) {
                width: 8%;
            }

            &:nth-of-type(6) {
                width: 8%;
            }

            &:nth-of-type(7) {
                width: 6%;
            }

            &:nth-of-type(8) {
                width: 8%;
            }

            &:nth-of-type(9) {
                width: 6%;
            }

            &:nth-of-type(10) {
                width: 6%;
            }

            &:nth-of-type(11) {
                width: 6%;
            }

            &:nth-of-type(12) {
                width: 6%;
            }

            &:nth-of-type(13) {
                width: 6%;
            }

            &:nth-of-type(14) {
                width: 6%;
            }

            &:last-of-type {
                width: auto;
            }
        }

        .operation-widget__table-wrap--executions & {
            text-align: right;

            &:first-of-type {
                text-align: left;
                width: 10%;
            }

            &:nth-of-type(2) {
                text-align: left;
                width: 10%;
            }

            &:nth-of-type(3) {
                text-align: left;
                width: 10%;
            }

            &:nth-of-type(4) {
                width: 10%;
            }

            &:nth-of-type(5) {
                width: 8%;
            }

            &:nth-of-type(6) {
                width: 10%;
            }

            &:nth-of-type(7) {
                width: 10%;
            }

            &:nth-of-type(8) {
                width: 8%;
            }

            &:nth-of-type(9) {
                width: 8%;
            }

            &:nth-of-type(10) {
                width: 8%;
            }

            &:last-of-type {
                width: auto;
            }
        }

        .operation-widget__table-wrap--balances & {
            &:first-of-type {
                width: 174px;
                @include below(1024px) {
                    width: 15%;
                }
            }

            &:nth-of-type(2) {
                width: 200px;
                @include below(1024px) {
                    width: 20%;
                }
            }

            &:nth-of-type(3) {
                width: 300px;
                @include below(1024px) {
                    width: 30%;
                }
            }

            &:nth-of-type(4) {
                width: 200px;
                @include below(1024px) {
                    width: 20%;
                }
            }

            &:nth-of-type(5) {
                width: 150px;
                @include below(1024px) {
                    width: 15%;
                }
            }
        }

        .operation-widget__table-wrap--news & {
            &:first-of-type {
                width: 180px;
            }

            &:nth-of-type(2) {
                width: 150px;
            }

            &:nth-of-type(3) {
                width: auto;
            }
        }

        .operation-widget__table-wrap--bets & {
            text-align: right;

            &:first-of-type {
                text-align: left;
                width: 9%;
            }

            &:nth-of-type(2) {
                text-align: left;
                width: 12%;
            }

            &:nth-of-type(3) {
                width: 11%;
            }

            &:nth-of-type(4) {
                width: 8%;
            }

            &:nth-of-type(5) {
                width: 8%;
            }

            &:nth-of-type(6) {
                width: 7%;
            }

            &:nth-of-type(7) {
                width: 8%;
            }

            &:nth-of-type(8) {
                width: 7%;
            }

            &:nth-of-type(9) {
                width: 7%;
            }

            &:nth-of-type(10) {
                width: 7%;
            }

            &:nth-of-type(11) {
                width: 7%;
            }

            &:nth-of-type(12) {
                width: 9%;
            }
        }

        .operation-widget__table-wrap--betsHistory & {
            text-align: right;

            &:first-of-type {
                text-align: left;
                width: 8%;
            }

            &:nth-of-type(2) {
                text-align: left;
                width: 11%;
            }

            &:nth-of-type(3) {
                width: 10%;
            }

            &:nth-of-type(4) {
                width: 7%;
            }

            &:nth-of-type(5) {
                width: 10%;
            }

            &:nth-of-type(6) {
                width: 7%;
            }

            &:nth-of-type(7) {
                width: 9%;
            }

            &:nth-of-type(8) {
                width: 7%;
            }

            &:nth-of-type(9) {
                width: 6%;
            }

            &:nth-of-type(10) {
                width: 9%;
            }

            &:nth-of-type(11) {
                width: 5%;
            }

            &:nth-of-type(12) {
                width: 5%;
            }

            &:nth-of-type(13) {
                width: 6%;
            }
        }
        /* stylelint-enable */
    }

    &__table-body {
        height: calc(100% - 28px);
    }

    &__table-cell {
        font-family: $font-stack-base;
        font-weight: $font--normal;
        font-size: 11px;
        line-height: 1.4;
        letter-spacing: 0.4px;
        padding-top: 6px;
        padding-right: 10px;
        padding-bottom: 7px;
        transition: color 0.2s ease-in, background-color 0.2s ease-in;
        vertical-align: top;

        @include themify() {
            color: themed($operation-widget__table-cell\color);
        }

        &:first-of-type {
            padding-left: 24px;
        }

        &:last-of-type {
            padding-right: 24px;
        }

        /* stylelint-disable no-descending-specificity */
        .operation-widget__table-wrap--positions & {
            text-align: right;
            vertical-align: top;

            &:first-of-type {
                text-align: left;
                width: 12%;
            }

            &:nth-of-type(2) {
                width: 7%;
            }

            &:nth-of-type(3) {
                width: 12%;
            }

            &:nth-of-type(4) {
                width: 7%;
            }

            &:nth-of-type(5) {
                width: 14%;
            }

            &:nth-of-type(6) {
                width: 7%;
            }

            &:nth-of-type(7) {
                width: 10%;
            }

            &:nth-of-type(8) {
                width: 7%;
            }

            &:nth-of-type(9) {
                width: 10%;
            }

            &:nth-of-type(10) {
                width: 7%;
            }

            &:last-of-type {
                width: auto;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        .operation-widget__table-wrap--orders &,
        .operation-widget__table-wrap--history & {
            text-align: right;

            &:first-of-type {
                text-align: left;
                width: 7%;
            }

            &:nth-of-type(2) {
                text-align: left;
                width: 10%;
            }

            &:nth-of-type(3) {
                width: 3%;
            }

            &:nth-of-type(4) {
                width: 8%;
            }

            &:nth-of-type(5) {
                width: 8%;
            }

            &:nth-of-type(6) {
                width: 8%;
            }

            &:nth-of-type(7) {
                width: 6%;
            }

            &:nth-of-type(8) {
                width: 8%;
            }

            &:nth-of-type(9) {
                width: 6%;
            }

            &:nth-of-type(10) {
                width: 6%;
            }

            &:nth-of-type(11) {
                width: 6%;
            }

            &:nth-of-type(12) {
                width: 6%;
            }

            &:nth-of-type(13) {
                width: 6%;
            }

            &:nth-of-type(14) {
                width: 6%;
            }

            &:last-of-type {
                width: auto;
            }
        }

        .operation-widget__table-wrap--orders & {
            &:last-of-type {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        .operation-widget__table-wrap--executions & {
            text-align: right;

            &:first-of-type {
                text-align: left;
                width: 10%;
            }

            &:nth-of-type(2) {
                text-align: left;
                width: 10%;
            }

            &:nth-of-type(3) {
                text-align: left;
                width: 10%;
            }

            &:nth-of-type(4) {
                width: 10%;
            }

            &:nth-of-type(5) {
                width: 8%;
            }

            &:nth-of-type(6) {
                width: 10%;
            }

            &:nth-of-type(7) {
                width: 10%;
            }

            &:nth-of-type(8) {
                width: 8%;
            }

            &:nth-of-type(9) {
                width: 8%;
            }

            &:nth-of-type(10) {
                width: 8%;
            }

            &:last-of-type {
                width: auto;
            }
        }

        .operation-widget__table-wrap--balances & {
            &:first-of-type {
                width: 174px;
                @include below(1024px) {
                    width: 15%;
                }
            }

            &:nth-of-type(2) {
                width: 200px;
                @include below(1024px) {
                    width: 20%;
                }
            }

            &:nth-of-type(3) {
                width: 300px;
                @include below(1024px) {
                    width: 30%;
                }
            }

            &:nth-of-type(4) {
                width: 200px;
                @include below(1024px) {
                    width: 20%;
                }
            }

            &:nth-of-type(5) {
                width: 150px;
                @include below(1024px) {
                    width: 15%;
                }
            }
        }

        .operation-widget__table-wrap--news & {
            &:first-of-type {
                width: 180px;
            }

            &:nth-of-type(2) {
                width: 150px;
                @include overflow-ellipsis;
            }

            &:nth-of-type(3) {
                width: auto;
            }
        }

        .operation-widget__table-wrap--bets & {
            text-align: right;
            vertical-align: top;

            &:first-of-type {
                text-align: left;
                width: 9%;
            }

            &:nth-of-type(2) {
                width: 12%;
            }

            &:nth-of-type(3) {
                width: 11%;
            }

            &:nth-of-type(4) {
                width: 8%;
            }

            &:nth-of-type(5) {
                width: 8%;
            }

            &:nth-of-type(6) {
                width: 7%;
            }

            &:nth-of-type(7) {
                width: 8%;
            }

            &:nth-of-type(8) {
                width: 7%;
            }

            &:nth-of-type(9) {
                width: 7%;
            }

            &:nth-of-type(10) {
                width: 7%;
            }

            &:nth-of-type(11) {
                width: 7%;
            }

            &:last-of-type {
                width: auto;
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        .operation-widget__table-wrap--betsHistory & {
            text-align: right;
            vertical-align: top;

            &:first-of-type {
                text-align: left;
                width: 8%;
            }

            &:nth-of-type(2) {
                width: 11%;
            }

            &:nth-of-type(3) {
                width: 10%;
            }

            &:nth-of-type(4) {
                width: 7%;
            }

            &:nth-of-type(5) {
                width: 10%;
            }

            &:nth-of-type(6) {
                width: 7%;
            }

            &:nth-of-type(7) {
                width: 9%;
            }

            &:nth-of-type(8) {
                width: 7%;
            }

            &:nth-of-type(9) {
                width: 6%;
            }

            &:nth-of-type(10) {
                width: 9%;
            }

            &:nth-of-type(11) {
                width: 5%;
            }

            &:nth-of-type(12) {
                width: 5%;
            }

            &:last-of-type {
                width: auto;
                @include overflow-ellipsis;
            }
        }

        /* stylelint-disable */
        .operation-widget__table-row--inactive & {
            @include themify() {
                color: themed($operation-widget__table-cell--inactive\color);
            }
        }
        /* stylelint-enable */

        .operation-widget__table-row:hover & {
            @include themify() {
                color: themed($operation-widget__table-cell-hover\color);
                background-color: themed($operation-widget__table-cell-hover\bg-color);
            }
        }
    }

    &__table-cell--profit {
        @include themify() {
            color: themed($operation-widget__table-cell--profit\color);
        }
    }

    &__table-cell--loss {
        @include themify() {
            color: themed($operation-widget__table-cell--loss\color);
        }
    }

    &__values-container {
        display: flex;
        flex-wrap: wrap;
    }

    &__values-container--end {
        justify-content: flex-end;
    }

    &__values-container--no-wrap {
        flex-wrap: nowrap;
    }

    &__values-container--pending-order-price,
    &__values-container--sltp {
        align-items: baseline;

        & + & {
            margin-left: 10px;
        }
    }

    &__time {
        display: flex;
        flex-wrap: wrap;

        &--left {
            justify-content: flex-start;
        }
        &--right {
            justify-content: flex-end;
        }
    }

    &__order-type {
        text-transform: uppercase;
    }

    &__order-type,
    &__profitLoss {
        transition: color 0.2s ease-in;

        .operation-widget__table-row--inactive & {
            @include themify() {
                color: themed($operation-widget__table-cell--inactive\color);
            }
        }

        .operation-widget__table-row:hover & {
            @include themify() {
                color: themed($operation-widget__table-cell-hover\color);
            }
        }

        &--profit {
            @include themify() {
                color: themed($operation-widget__table-cell--profit\color);
            }
        }

        &--loss {
            @include themify() {
                color: themed($operation-widget__table-cell--loss\color);
            }
        }
    }

    &__amount {
        transition: color 0.2s ease-in;

        .operation-widget__table-row--inactive & {
            @include themify() {
                color: themed($operation-widget__table-cell--inactive\color);
            }
        }

        .operation-widget__table-row:hover & {
            @include themify() {
                color: themed($operation-widget__table-cell-hover\color);
            }
        }
    }

    &__symbol-with-arrow {
        display: flex;
        align-items: center;
    }

    &__price-percent,
    &__volume-multiplier,
    &__position-type-id {
        margin-left: 5px;
        font-size: 0.85em;
        align-self: center;

        @include themify() {
            color: themed($operation-widget__table-cell--percent\color);
        }

        transition: color 0.2s ease-in;
        .operation-widget__table-row--inactive & {
            @include themify() {
                color: themed($operation-widget__table-cell--inactive\color);
            }
        }

        .operation-widget__table-row:hover & {
            @include themify() {
                color: themed($operation-widget__table-cell-hover\color);
            }
        }
    }

    &__pending-order-price,
    &__sltp-addon {
        margin-right: 7px;
        font-size: 0.85em;
        transition: color 0.2s ease-in;

        .operation-widget__table-row--inactive & {
            @include themify() {
                color: themed($operation-widget__table-cell--inactive\color);
            }
        }

        .operation-widget__table-row:hover & {
            @include themify() {
                color: themed($operation-widget__table-cell-hover\color);
            }
        }

        &--s,
        &--sl {
            @include themify() {
                color: themed($operation-widget__table-cell--loss\color);
            }
        }

        &--l,
        &--tp {
            @include themify() {
                color: themed($operation-widget__table-cell--profit\color);
            }
        }
    }

    &__arrow {
        transition: fill 0.2s ease-in;
        display: inline-block;
        width: 1.1em;
        height: 1.1em;
        padding-top: 1px;
        margin-left: 7px;

        @include above(1024px) {
            margin-left: 7px;
        }

        &--up {
            transform: rotate(-135deg);
            @include themify() {
                fill: themed($operation-widget__table-cell--profit\color);
            }
        }

        &--down {
            transform: rotate(-45deg);
            @include themify() {
                fill: themed($operation-widget__table-cell--loss\color);
            }
        }

        .operation-widget__table-row.operation-widget__table-row--inactive & {
            @include themify() {
                fill: themed($operation-widget__table-cell--percent\color);
            }
        }

        .operation-widget__table-row:hover & {
            @include themify() {
                fill: themed($operation-widget__table-cell-hover\color);
            }
        }
    }

    [data-tip='true'] {
        cursor: pointer;
    }

    &__table-btn {
        height: 18px; // TODO fix &__table-refresh height and font size
        width: 100%;
        max-width: 113px;
        padding-top: 3px;
        padding-bottom: 3px;
        font-family: $font-stack-base;
        font-weight: $font--normal;
        font-size: 11px;
        line-height: 1.4;
        letter-spacing: 0.4px;
        border-radius: 5px;
        transition: background-color 0.2s ease-in;

        @include themify() {
            color: themed($operation-widget__table-btn\color);
            background-color: themed($operation-widget__table-btn\bg-color);

            &:hover {
                background-color: themed($operation-widget__table-btn-hover\bg-color);
            }
        }

        &[disabled],
        &[disabled]:hover {
            opacity: 0.5;

            @include themify() {
                background-color: themed($operation-widget__table-btn\bg-color);
            }
        }

        &-commission {
            display: flex;
            flex-direction: column;
        }

        &-commission-row {
            width: 100%;
            display: flex;
            align-items: center;
            min-height: 14px;

            @include above(1024px) {
                min-height: 19px;
            }
        }

        &-commission-label {
            padding-right: 4px;
            margin-right: auto;
        }

        &-commission-value {
            display: inline-block;
            min-width: 36px;

            @include above(1024px) {
                min-width: 50px;
            }
        }

        &--short {
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            fill: currentColor;
        }

        &--light {
            @include themify() {
                color: themed($operation-widget__table-btn--light\color);
                background-color: themed($operation-widget__table-btn--light\bg-color);

                &:hover {
                    background-color: themed($operation-widget__table-btn-hover--light\bg-color);
                }
            }

            &[disabled],
            &[disabled]:hover {
                opacity: 0.5;

                @include themify() {
                    background-color: themed($operation-widget__table-btn--light\bg-color);
                }
            }
        }

        & + & {
            margin-left: 4px;
        }

        &-container {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-end;

            .btn {
                flex-grow: 0;
                flex-shrink: 0;
            }
        }
    }

    &__no-data {
        padding-top: 15px;
        padding-left: 20px;
    }

    &__table-preloader {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &__table-error {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &__table-error-text {
        margin-bottom: 10px;

        @include themify() {
            color: themed($operation-widget__table-error\color);
        }
    }

    &__table-refresh {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__table-refresh-icon {
        margin-right: 5px;
        fill: currentColor;
    }

    &__news-details {
        display: flex;
        flex-direction: column;
        height: 100%;

        &-header {
            padding-left: 19px;
            padding-right: 19px;
            flex-basis: 40px;
            flex-shrink: 0;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            border-bottom: 1px solid;

            @include themify() {
                border-color: themed($operation-widget__news-details-header\border-color);
            }
        }

        &-title {
            margin-left: 19px;
            padding-top: 8px;
            padding-bottom: 9px;
            font-size: 1.1em;
            font-family: $font-stack-base;
            font-weight: $font--normal;
            line-height: 1.4;
            letter-spacing: 0.4px;
            text-align: right;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            flex-grow: 1;

            @include themify() {
                color: themed($operation-widget__news-details-title\color);
            }
        }

        &-container {
            padding: 8px 0;
            width: 100%;
            height: 100%;
        }

        &-body {
            display: block;
            padding: 0 19px;
            @include themify() {
                color: themed($operation-widget__tool\color);
            }
        }
    }

    &__tool {
        transition: color 0.2s ease-in, background-color 0.2s ease-in;
        @include themify() {
            color: themed($operation-widget__tool\color);
            fill: themed($operation-widget__tool\color);
            background-color: themed($operation-widget__tool\bg-color);
        }

        &:hover:enabled {
            @include themify() {
                color: themed($operation-widget__tool--active\color);
                fill: themed($operation-widget__tool--active\color);
                background-color: themed($operation-widget__tool--active\bg-color);
            }
        }
    }

    &__return {
        height: 28px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 4px;
    }

    &__return-text {
        font-size: 1.1em;
        white-space: nowrap;
    }

    &__return-icon {
        margin-right: 6px;
        fill: currentColor;
    }
}
