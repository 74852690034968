.input {
    display: inline-block;
    position: relative;
    width: 100%;
    vertical-align: middle;

    &__label {
        font-size: 10px;
        position: absolute;
        left: 20px;
        opacity: 1;
        transform: translateY(3px);
        transition: opacity 0.2s ease-out, transform 0.2s ease-out;

        &--hidden {
            opacity: 0;
            transform: translateY(0);
        }

        @include themify() {
            color: themed($input__label\color);
        }
    }

    &__elem {
        display: block;
        background-color: transparent;
        border: 1px solid;
        border-radius: 4px;
        font-family: $font-stack-base;
        font-size: 22px;
        font-weight: $font--normal;
        height: 48px;
        line-height: 1;
        padding: 7px 20px 10px;
        text-align: left;
        transition: border 0.2s ease-out;
        width: 100%;

        @include themify() {
            border-color: themed($input__elem\border-color);
            color: themed($input__elem\color);
        }

        &:focus,
        &:hover {
            outline: 0;

            @include themify() {
                border-color: themed($input__elem--hover\border-color);
            }
        }

        &:focus {
            @include themify() {
                background-color: themed($input__elem--focus\bg-color);
            }
        }

        @include placeholder() {
            font-size: 16px;
            font-weight: $font--light;

            @include themify() {
                color: themed($input__label\color);
            }
        }

        .input--value & {
            padding-top: 15px;
            padding-bottom: 7px;
        }

        .input--error & {
            @include themify() {
                color: themed($input__elem--error\color);
                background-color: themed($input__elem--error\bg-color);
                border-color: themed($input__elem--error\border-color);
            }
        }

        .input--password & {
            padding-right: 40px;
        }
    }

    &__password-visibility-toggle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 13px;

        @include themify() {
            fill: themed($input__password-visibility-toggle\fill);
        }
    }
}
