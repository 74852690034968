// Your custom fonts here
// Expample: @include font-face("Lato", "../media/fonts/Lato/Lato-Light", swap, 300, $exts: woff2 woff);
@include font-face('Segoe UI', '../../media/fonts/SegoeUI/SegoeUI-Light', swap, $font--light, $exts: woff2 woff);
@include font-face('Segoe UI', '../../media/fonts/SegoeUI/SegoeUI', swap, $font--normal, $exts: woff2 woff);
@include font-face('Segoe UI', '../../media/fonts/SegoeUI/SegoeUI-SemiBold', swap, $font--semi-bold, $exts: woff2 woff);
@include font-face(
    'Roboto',
    '../../media/fonts/RobotoCondensed/RobotoCondensed-Light',
    swap,
    $font--light,
    $exts: woff2 woff
);
@include font-face(
    'Roboto',
    '../../media/fonts/RobotoCondensed/RobotoCondensed-Regular',
    swap,
    $font--normal,
    $exts: woff2 woff
);
