.main-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    height: 100vh;
    transform: translateX(-330px);
    transition: transform 0.4s ease-out, visibility 0.4s;
    visibility: hidden;

    &.is-open {
        transform: translateX(0);
        visibility: visible;
        width: 100%;
    }

    &.is-open &__overlay {
        flex-grow: 1;
        height: 100%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        flex: none;
        height: 100%;
        width: 330px;
        border-right: 1px solid;
        position: relative;
        z-index: 1;

        @include themify() {
            background-color: themed($main-menu\bg-color);
            border-right-color: themed($main-menu\border-color);
        }
    }

    &__header {
        flex: none;
        height: 62px;
    }

    &__header-inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 21px 21px 18px 51px;
    }

    &__company-name {
        width: 174px;
        font-size: 13px;
        font-weight: $font--light;
        line-height: 18.5px;
        letter-spacing: 0.4px;

        @include themify() {
            color: themed($main-menu__company-name\color);
        }
    }

    &__close-btn {
        @include themify() {
            fill: themed($main-menu__close-btn\fill);
        }
    }

    &__body-wrap {
        flex-grow: 1;
    }

    &__financial-operations {
        position: absolute;
        top: 0;
        left: 330px;
        height: 100%;
        width: 438px;
        padding-right: 32px;
        padding-left: 32px;
        padding-top: 62px;
        transform: translateX(-438px);
        transition: transform 0.4s ease-out, visibility 0.4s;
        visibility: hidden;

        @include themify() {
            background-color: themed($main-menu\bg-color);
        }

        .main-menu.is-financial-form-open & {
            transform: translateX(0);
            visibility: visible;
        }
    }

    &__section {
        position: relative;
        padding-top: 22px;
        padding-bottom: 40px;
        border-bottom: 1px solid;

        &:first-child {
            padding-top: 13px;
        }

        &:last-child {
            border-bottom: 0;
        }

        @include themify() {
            border-bottom-color: themed($main-menu\border-color);
        }
    }

    &__section-label {
        position: absolute;
        top: -9px;
        left: 51px;
        z-index: 1;
        padding-left: 7px;
        padding-right: 7px;
        font-size: 13px;
        font-weight: $font--light;
        letter-spacing: 0.4px;
        line-height: 1;

        @include themify() {
            color: themed($main-menu__section-label\color);
            background-color: themed($main-menu__section-label\bg-color);
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__item {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__link {
        width: 100%;
        display: block;
        padding: 12px 32px 12px 51px;
        text-decoration: none;
        transition: $transition--default;

        &.is-active {
            @include themify() {
                background-color: themed($main-menu__link--active\bg-color);
            }
        }

        &:hover {
            @include themify() {
                background-color: themed($main-menu__link--hover\bg-color);
            }
        }
    }

    &__link-inner {
        display: flex;
        align-items: center;
    }

    &__link-icon-wrap {
        margin-right: 20px;
    }

    &__link-icon {
        @include themify() {
            fill: themed($main-menu__link-icon\fill);
        }

        .main-menu__link.is-active & {
            @include themify() {
                fill: themed($main-menu__link-icon--active\fill);
            }
        }

        .main-menu__link:hover & {
            transition: fill 0.18s ease-out;

            @include themify() {
                fill: themed($main-menu__link-icon--hover\fill);
            }
        }
    }

    &__link-text {
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.14px;
        transition: color 0.2s ease-out;

        @include themify() {
            color: themed($main-menu__link-text\color);
        }

        .main-menu__link.is-active & {
            @include themify() {
                color: themed($main-menu__link-text--active\color);
            }
        }

        .main-menu__link:hover & {
            @include themify() {
                color: themed($main-menu__link-text--hover\color);
            }
        }
    }

    &__link-icon-arrow-wrap {
        position: absolute;
        right: 32px;
    }

    &__link-icon-arrow {
        @include themify() {
            fill: themed($main-menu___link-icon-arrow\fill);
        }

        .main-menu__link.is-active & {
            transform: rotate(-180deg);

            @include themify() {
                fill: themed($main-menu___link-icon-arrow--active\fill);
            }
        }

        .main-menu__link:hover & {
            @include themify() {
                fill: themed($main-menu___link-icon-arrow--hover\fill);
            }
        }
    }

    &__footer {
        flex: none;
        height: 78px;
        border-top: 1px solid;

        @include themify() {
            border-top-color: themed($main-menu\border-color);
        }
    }

    &__footer-inner {
        font-size: 13px;
        font-weight: $font--light;
        line-height: 1;
        letter-spacing: 0.4px;
        padding-left: 51px;
        padding-right: 51px;
        padding-top: 33px;
        padding-bottom: 31px;

        @include themify() {
            color: themed($main-menu__footer-inner\color);
        }
    }

    &__tumbler {
        display: flex;
        align-items: center;
    }

    &__tumbler-wrap {
        margin-top: 27px;
        padding-right: 32px;
        padding-left: 51px;
    }
}
