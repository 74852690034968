.auth-error-modal {
    &__content {
        position: absolute;
        border-radius: 4px;
        padding: 8px;
        padding-left: 15px;
        padding-right: 30px;
        width: 350px;

        @include themify() {
            background-color: themed($auth-error-modal__content\bg-color);
        }
    }

    &__message {
        font-size: 16px;

        @include themify() {
            color: themed($auth-error-modal__message\color);
        }
    }

    &__close-btn {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 12px;
        height: 12px;

        @include themify() {
            fill: themed($auth-error-modal__close-btn\fill);
        }
    }

    &__close-icon {
        vertical-align: top;
    }
}
