// =============================================================================
// Colors
// =============================================================================
$color--white: #fff;
$color--misty-rose: #ffe0d8;
$color--tomato: #f61e23;
$color--cinnabar: #e94d25;
$color--tia-maria: #c83610;
$color--gold: #e9d525;
$color--apple: #3c3;
$color--salad: #4ed44b;
$color--salem: #14a042;
$color--camarone: #006521;
$color--zircon: #f4f9ff;
$color--lavender: #e8f0fa;
$color--mystic: #dce2ea;
$color--spindle: #becdde;
$color--rock-blue: #a3b9d3;
$color--ship-cove: #7d9abc;
$color--ship-cove-l: #617791; // light theme contrast problem solving
$color--echo-blue: #9eb3cd;
$color--white-smoke: #f4f4f4;
$color--seashell: #f1f1f1;
$color--concrete: #f2f2f2;
$color--solitude: #eaedf0;
$color--alto: #d0d0d0;
$color--silver: #c4c4c4;
$color--cello: #3f4e60;
$color--madison: #2e415c;
$color--mirage: #1d2535;
$color--black-pearl: #161a23;
$color--bunker: #12151c;
$color--tangaroa: #090c13;
$color--thunder: #1e191c;

// =============================================================================
// Gradients
// =============================================================================
$linear-gradient--tomato: linear-gradient(182.97deg, #ff6b46 8.11%, #e94d25 91.89%), #ff6b46;
$linear-gradient--malachite: linear-gradient(183.15deg, #0d9239 3.67%, #0d8033 94.32%), #0ec24a;

// =============================================================================
// Properties (ensure the same names are used across all styles)
// =============================================================================

/// Naming convention
/// block__element--modifier\property
/// Examples:
/// $lang-switcher__dropdown\bg-color
/// $lang-switcher__btn--hover\color

// app-error
$app-error\color: '$app-error\color';
$app-error__message\color: '$app-error__message\color';
$app-error__return\color: '$app-error__return\color';
// auth-error-modal
$auth-error-modal__overlay\bg-color: '$auth-error-modal__overlay\bg-color';
$auth-error-modal__content\bg-color: '$auth-error-modal__content\bg-color';
$auth-error-modal__message\color: '$auth-error-modal__message\color';
$auth-error-modal__close-btn\fill: '$auth-error-modal__close-btn\fill';
// content-preloader
$content-preloader__dot\bg: '$content-preloader__dot\bg';
// input
$input__label\color: '$input__label\color';
$input__elem\border-color: '$input__elem\border-color';
$input__elem\color: '$input__elem\color';
$input__elem--hover\border-color: '$input__elem--hover\border-color';
$input__elem--focus\bg-color: '$input__elem--focus\bg-color';
$input__elem--error\color: '$input__elem--error\color';
$input__elem--error\bg-color: '$input__elem--error\bg-color';
$input__elem--error\border-color: '$input__elem--error\border-color';
$input__password-visibility-toggle\fill: '$input__password-visibility-toggle\fill';
// select
$select\color: '$select\color';
$select\bg-color: '$select\bg-color';
$select--focus\bg-color: '$select--focus\bg-color';
$select\border-color: '$select\border-color';
$select--hover\border-color: '$select--hover\border-color';
$select\placeholder-color: '$select\placeholder-color';
$select\control-color: '$select\control-color';
$select--error\color: '$select--error\color';
$select--error\bg-color: '$select--error\bg-color';
$select--error\border-color: '$select--error\border-color';
$select-dropdown\color: '$select-dropdown\color';
$select-dropdown\bg-color: '$select-dropdown\bg-color';
$select-dropdown-item--focus\color: '$select-dropdown-item--focus\color';
$select-dropdown-item--focus\bg-color: '$select-dropdown-item--focus\bg-color';
$select-dropdown-item--selected\color: '$select-dropdown-item--selected\color';
$select-dropdown-item--selected\bg-color: '$select-dropdown-item--selected\bg-color';
// lang-switcher
$lang-switcher\color: '$lang-switcher\color';
$lang-switcher__dropdown\bg-color: '$lang-switcher__dropdown\bg-color';
$lang-switcher__btn--hover\bg-color: '$lang-switcher__btn--hover\bg-color';
$lang-switcher__btn--hover\color: '$lang-switcher__btn--hover\color';
// login-form
$login-form__btn\color: '$login-form__btn\color';
$login-form__btn--demo\bg-color: '$login-form__btn--demo\bg-color';
$login-form__btn--submit\bg-color: '$login-form__btn--submit\bg-color';
$login-form__btn--disabled\bg-color: '$login-form__btn--disabled\bg-color';
$login-form__btn--disabled\color: '$login-form__btn--disabled\color';
$login-form__grecaptcha-note\color: '$login-form__grecaptcha-note\color';
$login-form__grecaptcha-note-link\color: '$login-form__grecaptcha-note-link\color';
// login-screen
$login-screen__title\color: '$login-screen__title\color';
// main-logo
$main-logo\color: '$main-logo\color';
// main-menu
$main-menu\bg-color: '$main-menu\bg-color';
$main-menu__company-name\color: '$main-menu__company-name\color';
$main-menu__close-btn\fill: '$main-menu__close-btn\fill';
$main-menu\border-color: '$main-menu\border-color';
$main-menu__section-label\color: '$main-menu__section-label\color';
$main-menu__section-label\bg-color: '$main-menu__section-label\bg-color';
$main-menu__link-icon\fill: '$main-menu__link-icon\fill';
$main-menu__link-text\color: '$main-menu__link-text\color';
$main-menu__footer-inner\color: '$main-menu__footer-inner\color';
$main-menu__body--after\bg-color: '$main-menu__body--after\bg-color';
$main-menu__link--active\bg-color: '$main-menu__link--active\bg-color';
$main-menu__link--hover\bg-color: '$main-menu__link--hover\bg-color';
$main-menu__link-icon--active\fill: '$main-menu__link-icon--active\fill';
$main-menu__link-icon--hover\fill: '$main-menu__link-icon--hover\fill';
$main-menu__link-text--active\color: '$main-menu__link-text--active\color';
$main-menu__link-text--hover\color: '$main-menu__link-text--hover\color';
$main-menu___link-icon-arrow\fill: '$main-menu___link-icon-arrow\fill';
$main-menu___link-icon-arrow--active\fill: '$main-menu___link-icon-arrow--active\fill';
$main-menu___link-icon-arrow--hover\fill: '$main-menu___link-icon-arrow--hover\fill';

// main-menu-toggle
$main-menu-toggle\color: '$main-menu-toggle\color';
// mode-tumbler
$mode-tubmler\color: '$mode-tubmler\color';
// page
$page\bg-color: '$page\bg-color';
$page\color: '$page\color';
$page\outline-color: '$page\outline-color';
$page__main\bg-color: '$page__main\bg-color';
// page-area
$page-area\bg-color: '$page-area\bg-color';
// page-header
$page-header\bg-color: '$page-header\bg-color';
$page-header__logout-btn\color: '$page-header__logout-btn\color';
// unauthenticated-layout
$unauthenticated-layout__logo\fill: '$unauthenticated-layout__logo\fill';
// user
$user__self\color: '$user__self\color';
$user__inner--short\bg-color: '$user__inner--short\bg-color';
// connection indicator near user
$user__connection\bg-color: '$user__connection\bg-color';
$user__connection--normal\bg-color: '$user__connection--normal\bg-color';
$user__connection--warn\bg-color: '$user__connection--warn\bg-color';
$user__connection--problem\bg-color: '$user__connection--problem\bg-color';
// time widget
$time-widget\color: '$time-widget\color';

// symbols-widget
$symbols-widget__header\border-color: '$symbols-widget__header\border-color';
$symbols-widget__footer\border-color: '$symbols-widget__footer\border-color';
$symbols-widget__title\color: '$symbols-widget__title\color';
$symbols-widget__groups\bg-color: '$symbols-widget__groups\bg-color';
$symbols-widget__groups-nav\color: '$symbols-widget__groups-nav\color';
$symbols-widget__groups-nav\border-color: '$symbols-widget__groups-nav\border-color';
$symbols-widget__groups-name\color: '$symbols-widget__groups-name\color';
$symbols-widget__groups-name--active\color: '$symbols-widget__groups-name--active\color';
$symbols-widget__groups-name--active\bg-color: '$symbols-widget__groups-name--active\bg-color';
$symbols-widget__table-head\color: '$symbols-widget__table-head\color';
$symbols-widget__table-head\border-color: '$symbols-widget__table-head\border-color';
$symbols-widget__table-td\color: '$symbols-widget__table-td\color';
$symbols-widget__table-td--hover\color: '$symbols-widget__table-td--hover\color';
$symbols-widget__table-td--hover\bg-color: '$symbols-widget__table-td--hover\bg-color';
$symbols-widget__table-td--up\color: '$symbols-widget__table-td--up\color';
$symbols-widget__table-td--down\color: '$symbols-widget__table-td--down\color';
$symbols-widget__table-td--inactive\color: '$symbols-widget__table-td--inactive\color';
$symbols-widget__table-gag\color: '$symbols-widget__table-gag\color';
$symbols-widget__details-title\color: '$symbols-widget__details-title\color';
$symbols-widget__details-price-info-value\color: '$symbols-widget__details-price-info-value\color';
$symbols-widget__details-price-info-value--up\color: '$symbols-widget__details-price-info-value--up\color';
$symbols-widget__details-price-info-value--down\color: '$symbols-widget__details-price-info-value--down\color';
$symbols-widget__details-price-info-label\color: '$symbols-widget__details-price-info-label\color';
$symbols-widget__details-body\color: '$symbols-widget__details-body\color';
$symbols-widget__details-spread\color: '$symbols-widget__details-spread\color';
$symbols-widget__details-action\color: '$symbols-widget__details-action\color';
$symbols-widget__details-action--buy\bg-color: '$symbols-widget__details-action--buy\bg-color';
$symbols-widget__details-action--sell\bg-color: '$symbols-widget__details-action--sell\bg-color';
$symbols-widget__details-action--buy-hover\bg-color: '$symbols-widget__details-action--buy-hover\bg-color';
$symbols-widget__details-action--sell-hover\bg-color: '$symbols-widget__details-action--sell-hover\bg-color';
$symbols-widget__details-action--disabled\bg-color: '$symbols-widget__details-action--disabled\bg-color';
$symbols-widget__details-action--disabled\color: '$symbols-widget__details-action--disabled\color';
$symbols-widget__details-volume-input\color: '$symbols-widget__details-volume-input\color';
$symbols-widget__details-volume-input\bg-color: '$symbols-widget__details-volume-input\bg-color';
$symbols-widget__details-volume-input\border-color: '$symbols-widget__details-volume-input\border-color';
$symbols-widget__details-volume-input--order\border-color: '$symbols-widget__details-volume-input--order\border-color';
$symbols-widget__details-volume-input-placeholder\color: '$symbols-widget__details-volume-input-placeholder\color';
$symbols-widget__details-volume-input--focus\bg-color: '$symbols-widget__details-volume-input--focus\bg-color';
$symbols-widget__details-volume-input--focus\border-color: '$symbols-widget__details-volume-input--focus\border-color';
$symbols-widget__details-volume-input-btn--minus\bg-color: '$symbols-widget__details-volume-input-btn--minus\bg-color';
$symbols-widget__details-volume-input-btn--plus\bg-color: '$symbols-widget__details-volume-input-btn--plus\bg-color';
$symbols-widget__details-volume-input-btn\color: '$symbols-widget__details-volume-input-btn\color';
$symbols-widget__details-volume-input-btn--active\border-color: '$symbols-widget__details-volume-input-btn--active\border-color';
$symbols-widget__details-star\fill: '$symbols-widget__details-star\fill';
$symbols-widget__details-star--active\fill: '$symbols-widget__details-star--active\fill';
$symbols-widget__details-chart-icon\fill: '$symbols-widget__details-chart-icon\fill';
$symbols-widget__details-chart-icon--hover\fill: '$symbols-widget__details-chart-icon--hover\fill';
$symbols-widget__details-chart-icon--active\fill: '$symbols-widget__details-chart-icon--active\fill';
$symbols-widget__details-bet-delimiter\color: '$symbols-widget__details-bet-delimiter\color';
$symbols-widget__details-bet-delimiter\border-color: '$symbols-widget__details-bet-delimiter\border-color';
$symbols-widget__search-close\fill: '$symbols-widget__search-close\fill';
$symbols-widget__search-close--hover\fill: '$symbols-widget__search-close--hover\fill';
$symbols-widget__search-input\color: '$symbols-widget__search-input\color';
$symbols-widget__search-input\bg-color: '$symbols-widget__search-input\bg-color';
$symbols-widget__search-input\border-color: '$symbols-widget__search-input\border-color';
$symbols-widget__search-input-placeholder\color: '$symbols-widget__search-input-placeholder\color';
$symbols-widget__search-dropdown\color: '$symbols-widget__search-dropdown\color';
$symbols-widget__search-dropdown\bg-color: '$symbols-widget__search-dropdown\bg-color';
$symbols-widget__search-dropdown-item--active\color: '$symbols-widget__search-dropdown-item--active\color';
$symbols-widget__search-dropdown-item--active\bg-color: '$symbols-widget__search-dropdown-item--active\bg-color';
$symbols-widget__list-placeholder\fill: '$symbols-widget__list-placeholder\fill';
$symbols-widget__favorite-icon\fill: '$symbols-widget__favorite-icon\fill';
$symbols-widget__favorite-icon--active\fill: '$symbols-widget__favorite-icon--active\fill';
$symbols-widget__favorite-icon--hover\fill: '$symbols-widget__favorite-icon-hover\fill';
$symbols-widget__insurance-tumbler\bg-color: '$symbols-widget__insurance-tumbler\bg-color';
$symbols-widget__insurance-tumbler--checked\bg-color: '$symbols-widget__insurance-tumbler--checked\bg-color';
$symbols-widget__decorated-value\bg-color: '$symbols-widget__decorated-value\bg-color';
$symbols-widget__decorated-value\color: '$symbols-widget__decorated-value\color';
// chart-widget
$chart-widget__tabs\bg-color: '$chart-widget__tabs\bg-color';
$chart-widget__tabs-nav\bg-color: '$chart-widget__tabs-nav\bg-color';
$chart-widget__tabs-nav\color: '$chart-widget__tabs-nav\color';
$chart-widget__tabs-nav\border-color: '$chart-widget__tabs-nav\border-color';
$chart-widget__tabs-name\color: '$chart-widget__tabs-name\color';
$chart-widget__tabs-name\bg-color: '$chart-widget__tabs-name\bg-color';
$chart-widget__tabs-name--hover\color: '$chart-widget__tabs-name--hover\color';
$chart-widget__tabs-name--active\color: '$chart-widget__tabs-name--active\color';
$chart-widget__tabs-name--active\bg-color: '$chart-widget__tabs-name--active\bg-color';
$chart-widget__tabs-name--active\border-color: '$chart-widget__tabs-name--active\border-color';
$chart-widget__tabs-close\fill: '$chart-widget__tabs-close\fill';
$chart-widget__new-tab\fill: '$chart-widget__new-tab\fill';
$chart-widget__new-tab\bg-color: '$chart-widget__new-tab\bg-color';
$chart-widget__tools\border-color: '$chart-widget__tools\border-color';
$chart-widget__tools-drag\fill: '$chart-widget__tools-drag\fill';
$chart-widget__tool\color: '$chart-widget__tool\color';
$chart-widget__tool\bg-color: '$chart-widget__tool\bg-color';
$chart-widget__tool--active\color: '$chart-widget__tool--active\color';
$chart-widget__tool--active\bg-color: '$chart-widget__tool--active\bg-color';
$chart-widget__dropdown-content\bg-color: '$chart-widget__dropdown-content\bg-color';
$chart-widget__dropdown-btn\color: '$chart-widget__dropdown-btn\color';
$chart-widget__dropdown-btn--active\color: '$chart-widget__btn--active\color';
$chart-widget__dropdown-btn--active\bg-color: '$chart-widget__dropdown-btn--active\bg-color';
$chart-widget__dropdown-btn--hover\bg-color: '$chart-widget__dropdown-btn--hover\bg-color';
$chart-widget__dropdown-btn--hover\color: '$lang-switcher__dropdown-btn--hover\color';
$chart-widget__search-input\color: '$chart-widget__search-input\color';
$chart-widget__search-input\bg-color: '$chart-widget__search-input\bg-color';
$chart-widget__search-input\border-color: '$chart-widget__search-input\border-color';
$chart-widget__search-input-placeholder\color: '$chart-widget__search-input-placeholder\color';
$chart-widget__chart-placeholder\fill: '$chart-widget__chart-placeholder\fill';
$symbols-widget__details-favorite-icon\fill: '$symbols-widget__details-favorite-icon\fill';
$symbols-widget__details-favorite-icon-active\fill: '$symbols-widget__details-favorite-icon-active\fill';
// operation-widget
$operation-widget__tabs-btn\color: '$operation-widget__tabs-btn\color';
$operation-widget__tabs-btn--active\color: '$operation-widget__tabs-btn-active\color';
$operation-widget__tabs-nav\bg-color: '$operation-widget__tabs-nav\bg-color';
$operation-widget__tabs-nav\color: '$operation-widget__tabs-nav\color';
$operation-widget__tabs-nav\border-color: '$operation-widget__tabs-nav\border-color';
$operation-widget__tabs-list\bg-color: '$operation-widget__tabs-list\bg-color';
$operation-widget__tabs-item--active\bg-color: '$operation-widget__tabs-item--active\bg-color';
$operation-widget__tabs-item--has-notification\bg-color: '$operation-widget__tabs-item--has-notification\bg-color';
$operation-widget__tabs-item--not-active-hover\color: '$operation-widget__tabs-item--not-active-hover\color';
$operation-widget__table-head-cell\color: '$operation-widget__table-head-cell\color';
$operation-widget__table-head\border-color: '$operation-widget__table-head\border-color';
$operation-widget__table-cell\color: '$operation-widget__table-cell\color';
$operation-widget__table-cell-hover\bg-color: '$operation-widget__table-cell-hover\bg-color';
$operation-widget__table-cell-hover\color: '$operation-widget__table-cell-hover\color';
$operation-widget__table-btn\color: '$operation-widget__table-btn\color';
$operation-widget__table-btn\bg-color: '$operation-widget__table-btn\bg-color';
$operation-widget__table-btn-hover\bg-color: '$operation-widget__table-btn-hover\bg-color';
$operation-widget__table-btn--light\color: '$operation-widget__table-btn--light\color';
$operation-widget__table-btn--light\bg-color: '$operation-widget__table-btn--light\bg-color';
$operation-widget__table-btn-hover--light\bg-color: '$operation-widget__table-btn-hover--light\bg-color';
$operation-widget__table-cell--profit\color: '$operation-widget__table-cell--profit\color';
$operation-widget__table-cell--loss\color: '$operation-widget__table-cell--loss\color';
$operation-widget__table-cell--percent\color: '$operation-widget__table-cell--percent\color';
$operation-widget__table-cell--inactive\color: '$operation-widget__table-cell--inactive\color';
$operation-widget__table-error\color: '$operation-widget__table-error\color';
$operation-widget__news-details-title\color: '$operation-widget__news-details-title\color';
$operation-widget__news-details-header\border-color: '$operation-widget__news-details-header\border-color';
$operation-widget__tool\color: '$operation-widget__tool\color';
$operation-widget__tool\bg-color: '$operation-widget__tool\bg-color';
$operation-widget__tool--active\color: '$operation-widget__tool--active\color';
$operation-widget__tool--active\bg-color: '$operation-widget__tool--active\bg-color';
// custom-scrollbar
$custom-scrollbar__thumb-vertical\bg-color: '$custom-scrollbar__thumb-vertical\bg-color';
$custom-scrollbar__thumb-vertical-hover\bg-color: '$custom-scrollbar__thumb-vertical-hover\bg-color';
// state-account
$header-state__item-description\color: '$header-state__item-description\color';
$header-state__item-value\color: '$header-state__item-value\color';
//financial-operations-form
$financial-operations-form__title\color: '$financial-operations-form__title\color';
$financial-operations-form__submit-btn\color: '$financial-operations-form__submit-btn\color';
$financial-operations-form__submit-btn\bg-color: '$financial-operations-form__submit-btn\bg-color';
$financial-operations-form__submit-btn--hover\bg-color: '$financial-operations-form__submit-btn--hover\bg-color';
//variation-group
$variation-group\bg-color: '$variation-group\bg-color';
$variation-group\color: '$variation-group\color';
$variation-group__item--selected\bg-color: '$variation-group__item--selected\bg-color';
$variation-group__item--selected\color: '$variation-group__item--selected\color';
// date-time
$date-time--color\color: '$date-time--color\color';
// tool
$tool\color: '$tool\color';
$tool\bg-color: '$tool\bg-color';
$tool--active\color: '$tool--active\color';
$tool--active\bg-color: '$tool--active\bg-color';
// dropdown
$state-account-dropdown-content\bg-color: '$state-account-dropdown-content\bg-color';

$themes: (
    dark: (
        #{$app-error\color}: $color--madison,
        #{$app-error__message\color}: $color--cinnabar,
        #{$app-error__return\color}: $color--ship-cove,
        #{$auth-error-modal__overlay\bg-color}: $color--mirage,
        #{$auth-error-modal__content\bg-color}: $color--white,
        #{$auth-error-modal__message\color}: $color--cinnabar,
        #{$auth-error-modal__close-btn\fill}: $color--cello,
        #{$content-preloader__dot\bg}: $color--white,
        #{$input__label\color}: $color--cello,
        #{$input__elem\border-color}: $color--ship-cove,
        #{$input__elem\color}: $color--white,
        #{$input__elem--hover\border-color}: $color--white,
        #{$input__elem--focus\bg-color}: $color--black-pearl,
        #{$input__elem--error\color}: $color--cinnabar,
        #{$input__elem--error\bg-color}: $color--thunder,
        #{$input__elem--error\border-color}: $color--cinnabar,
        #{$input__password-visibility-toggle\fill}: $color--ship-cove,
        #{$select\color}: $color--white,
        #{$select\bg-color}: $color--bunker,
        #{$select--focus\bg-color}: $color--tangaroa,
        #{$select\border-color}: $color--bunker,
        #{$select--hover\border-color}: $color--tangaroa,
        #{$select\placeholder-color}: $color--cello,
        #{$select\control-color}: $color--ship-cove,
        #{$select--error\color}: $color--cinnabar,
        #{$select--error\bg-color}: $color--thunder,
        #{$select--error\border-color}: $color--cinnabar,
        #{$select-dropdown\color}: $color--ship-cove,
        #{$select-dropdown\bg-color}: $color--bunker,
        #{$select-dropdown-item--focus\color}: $color--white,
        #{$select-dropdown-item--focus\bg-color}: $color--tangaroa,
        #{$select-dropdown-item--selected\color}: $color--white,
        #{$select-dropdown-item--selected\bg-color}: $color--bunker,
        #{$lang-switcher\color}: $color--ship-cove,
        #{$lang-switcher__dropdown\bg-color}: $color--tangaroa,
        #{$lang-switcher__btn--hover\bg-color}: $color--black-pearl,
        #{$lang-switcher__btn--hover\color}: $color--white,
        #{$login-form__btn\color}: $color--white,
        #{$login-form__btn--demo\bg-color}: $color--madison,
        #{$login-form__btn--submit\bg-color}: $linear-gradient--malachite,
        #{$login-form__btn--disabled\bg-color}: $color--mirage,
        #{$login-form__btn--disabled\color}: $color--cello,
        #{$login-form__grecaptcha-note\color}: $color--cello,
        #{$login-form__grecaptcha-note-link\color}: $color--ship-cove,
        #{$login-screen__title\color}: $color--white,
        #{$main-logo\color}: $color--white,
        #{$main-menu\bg-color}: $color--bunker,
        #{$main-menu__company-name\color}: $color--cinnabar,
        #{$main-menu__close-btn\fill}: $color--silver,
        #{$main-menu\border-color}: rgba($color--concrete, 0.03),
        #{$main-menu__section-label\color}: $color--cello,
        #{$main-menu__section-label\bg-color}: $color--bunker,
        #{$main-menu__link-icon\fill}: $color--mirage,
        #{$main-menu__link-text\color}: $color--ship-cove,
        #{$main-menu__footer-inner\color}: $color--cello,
        #{$main-menu-toggle\color}: $color--ship-cove,
        #{$main-menu__body--after\bg-color}: rgba($color--concrete, 0.03),
        #{$main-menu__link--active\bg-color}: $color--black-pearl,
        #{$main-menu__link--hover\bg-color}: $color--tangaroa,
        #{$main-menu__link-text--hover\color}: $color--white,
        #{$main-menu__link-text--active\color}: $color--spindle,
        #{$main-menu__link-icon--hover\fill}: $color--white,
        #{$main-menu__link-icon--active\fill}: $color--lavender,
        #{$main-menu___link-icon-arrow\fill}: $color--ship-cove,
        #{$main-menu___link-icon-arrow--hover\fill}: $color--white,
        #{$main-menu___link-icon-arrow--active\fill}: $color--spindle,
        #{$mode-tubmler\color}: $color--cinnabar,
        #{$symbols-widget__insurance-tumbler\bg-color}: $color--cinnabar,
        #{$symbols-widget__insurance-tumbler--checked\bg-color}: $color--camarone,
        #{$page\bg-color}: $color--bunker,
        #{$page\color}: $color--white,
        #{$page\outline-color}: $color--white,
        #{$page__main\bg-color}: $color--tangaroa,
        #{$page-area\bg-color}: $color--black-pearl,
        #{$page-header\bg-color}: $color--black-pearl,
        #{$page-header__logout-btn\color}: $color--white,
        #{$unauthenticated-layout__logo\fill}: $color--white,
        #{$user__self\color}: $color--white,
        #{$user__inner--short\bg-color}: $color--bunker,
        #{$user__connection\bg-color}: $color--ship-cove,
        #{$user__connection--normal\bg-color}: $color--apple,
        #{$user__connection--warn\bg-color}: $color--gold,
        #{$user__connection--problem\bg-color}: $color--cinnabar,
        #{$time-widget\color}: $color--madison,
        #{$symbols-widget__header\border-color}: $color--tangaroa,
        #{$symbols-widget__footer\border-color}: $color--tangaroa,
        #{$symbols-widget__title\color}: $color--white,
        #{$tool\color}: $color--ship-cove,
        #{$tool\bg-color}: $color--bunker,
        #{$tool--active\color}: $color--white,
        #{$tool--active\bg-color}: $color--tangaroa,
        #{$symbols-widget__groups-nav\color}: $color--ship-cove,
        #{$symbols-widget__groups\bg-color}: $color--bunker,
        #{$symbols-widget__groups-nav\border-color}: $color--tangaroa,
        #{$symbols-widget__groups-name\color}: $color--ship-cove,
        #{$symbols-widget__groups-name--active\color}: $color--white,
        #{$symbols-widget__groups-name--active\bg-color}: $color--black-pearl,
        #{$symbols-widget__table-head\color}: $color--cello,
        #{$symbols-widget__table-head\border-color}: rgba($color--concrete, 0.03),
        #{$symbols-widget__table-td\color}: $color--ship-cove,
        #{$symbols-widget__table-td--hover\color}: $color--white,
        #{$symbols-widget__table-td--hover\bg-color}: $color--bunker,
        #{$symbols-widget__table-td--up\color}: $color--salem,
        #{$symbols-widget__table-td--down\color}: $color--cinnabar,
        #{$symbols-widget__table-td--inactive\color}: rgba($color--ship-cove, 0.6),
        #{$symbols-widget__table-gag\color}: rgba($color--ship-cove, 0.14),
        #{$symbols-widget__details-title\color}: $color--white,
        #{$symbols-widget__details-price-info-value\color}: $color--ship-cove,
        #{$symbols-widget__details-price-info-value--up\color}: $color--salem,
        #{$symbols-widget__details-price-info-value--down\color}: $color--cinnabar,
        #{$symbols-widget__details-price-info-label\color}: $color--ship-cove,
        #{$symbols-widget__details-body\color}: $color--ship-cove,
        #{$symbols-widget__details-spread\color}: $color--ship-cove,
        #{$symbols-widget__details-action\color}: $color--white,
        #{$symbols-widget__details-action--buy\bg-color}: $color--salem,
        #{$symbols-widget__details-action--sell\bg-color}: $color--cinnabar,
        #{$symbols-widget__details-action--disabled\bg-color}: $color--bunker,
        #{$symbols-widget__details-action--disabled\color}: rgba($color--ship-cove, 0.4),
        #{$symbols-widget__details-action--buy-hover\bg-color}: $color--camarone,
        #{$symbols-widget__details-action--sell-hover\bg-color}: $color--tia-maria,
        #{$symbols-widget__details-volume-input\color}: $color--ship-cove,
        #{$symbols-widget__details-volume-input-placeholder\color}: $color--cello,
        #{$symbols-widget__details-volume-input\bg-color}: $color--bunker,
        #{$symbols-widget__details-volume-input\border-color}: $color--bunker,
        #{$symbols-widget__details-volume-input--order\border-color}: rgba(9, 12, 19, 0.5),
        #{$symbols-widget__details-volume-input--focus\bg-color}: $color--tangaroa,
        #{$symbols-widget__details-volume-input--focus\border-color}: $color--tangaroa,
        #{$symbols-widget__details-star\fill}: $color--cello,
        #{$symbols-widget__details-star--active\fill}: $color--cinnabar,
        #{$symbols-widget__details-chart-icon\fill}: $color--cello,
        #{$symbols-widget__details-chart-icon--active\fill}: $color--salem,
        #{$symbols-widget__details-volume-input-btn--minus\bg-color}: $color--tomato,
        #{$symbols-widget__details-volume-input-btn--plus\bg-color}: $color--salad,
        #{$symbols-widget__details-volume-input-btn\color}: $color--tangaroa,
        #{$symbols-widget__details-volume-input-btn--active\border-color}: rgba(9, 12, 19, 0.5),
        #{$symbols-widget__search-close\fill}: $color--cello,
        #{$symbols-widget__search-close--hover\fill}: $color--white,
        #{$symbols-widget__search-input\color}: $color--white,
        #{$symbols-widget__search-input\bg-color}: $color--tangaroa,
        #{$symbols-widget__search-input\border-color}: $color--black-pearl,
        #{$symbols-widget__search-input-placeholder\color}: $color--cello,
        #{$symbols-widget__search-dropdown\color}: $color--cello,
        #{$symbols-widget__search-dropdown\bg-color}: $color--tangaroa,
        #{$symbols-widget__search-dropdown-item--active\color}: $color--white,
        #{$symbols-widget__search-dropdown-item--active\bg-color}: $color--bunker,
        #{$symbols-widget__list-placeholder\fill}: $color--ship-cove,
        #{$symbols-widget__details-bet-delimiter\color}: $color--cello,
        #{$symbols-widget__details-bet-delimiter\border-color}: rgba($color--concrete, 0.03),
        #{$symbols-widget__decorated-value\bg-color}: $color--mirage,
        #{$symbols-widget__decorated-value\color}: $color--ship-cove,
        #{$chart-widget__tabs\bg-color}: $color--bunker,
        #{$chart-widget__tabs-nav\color}: $color--ship-cove,
        #{$chart-widget__tabs-nav\bg-color}: $color--bunker,
        #{$chart-widget__tabs-nav\border-color}: $color--tangaroa,
        #{$chart-widget__tabs-name\color}: $color--ship-cove,
        #{$chart-widget__tabs-name--hover\color}: $color--white,
        #{$chart-widget__tabs-name--active\color}: $color--white,
        #{$chart-widget__tabs-name--active\bg-color}: $color--black-pearl,
        #{$chart-widget__tabs-name--active\border-color}: $color--tangaroa,
        #{$chart-widget__tabs-close\fill}: $color--ship-cove,
        #{$chart-widget__new-tab\fill}: $color--ship-cove,
        #{$chart-widget__new-tab\bg-color}: $color--black-pearl,
        #{$chart-widget__tools\border-color}: $color--tangaroa,
        #{$chart-widget__tools-drag\fill}: $color--cello,
        #{$chart-widget__tool\color}: $color--ship-cove,
        #{$chart-widget__tool\bg-color}: $color--bunker,
        #{$chart-widget__tool--active\color}: $color--white,
        #{$chart-widget__tool--active\bg-color}: $color--tangaroa,
        #{$chart-widget__dropdown-content\bg-color}: $color--tangaroa,
        #{$chart-widget__dropdown-btn\color}: $color--ship-cove,
        #{$chart-widget__dropdown-btn--active\color}: $color--white,
        #{$chart-widget__dropdown-btn--active\bg-color}: $color--black-pearl,
        #{$chart-widget__dropdown-btn--hover\bg-color}: $color--black-pearl,
        #{$chart-widget__dropdown-btn--hover\color}: $color--white,
        #{$chart-widget__search-input\color}: $color--white,
        #{$chart-widget__search-input\bg-color}: $color--tangaroa,
        #{$chart-widget__search-input\border-color}: $color--black-pearl,
        #{$chart-widget__search-input-placeholder\color}: $color--cello,
        #{$chart-widget__chart-placeholder\fill}: $color--ship-cove,
        #{$operation-widget__tabs-btn\color}: $color--ship-cove,
        #{$operation-widget__tabs-btn--active\color}: $color--white,
        #{$operation-widget__tabs-nav\color}: $color--ship-cove,
        #{$operation-widget__tabs-nav\bg-color}: $color--bunker,
        #{$operation-widget__tabs-nav\border-color}: $color--tangaroa,
        #{$operation-widget__tabs-list\bg-color}: $color--bunker,
        #{$operation-widget__tabs-item--active\bg-color}: $color--black-pearl,
        #{$operation-widget__tabs-item--not-active-hover\color}: $color--white,
        #{$operation-widget__tabs-item--has-notification\bg-color}: $color--cinnabar,
        #{$operation-widget__table-head-cell\color}: $color--cello,
        #{$operation-widget__table-head\border-color}: rgba($color--concrete, 0.03),
        #{$operation-widget__table-cell\color}: $color--ship-cove,
        #{$operation-widget__table-cell-hover\bg-color}: $color--bunker,
        #{$operation-widget__table-cell-hover\color}: $color--white,
        #{$operation-widget__table-cell--inactive\color}: rgba($color--ship-cove, 0.6),
        #{$operation-widget__table-btn\color}: $color--white,
        #{$operation-widget__table-btn\bg-color}: $color--madison,
        #{$operation-widget__table-btn-hover\bg-color}: $color--mirage,
        #{$operation-widget__table-btn--light\color}: $color--ship-cove,
        #{$operation-widget__table-btn--light\bg-color}: $color--mirage,
        #{$operation-widget__table-btn-hover--light\bg-color}: $color--tangaroa,
        #{$operation-widget__table-cell--profit\color}: $color--salem,
        #{$operation-widget__table-cell--loss\color}: $color--cinnabar,
        #{$operation-widget__table-cell--percent\color}: $color--cello,
        #{$operation-widget__table-error\color}: $color--cinnabar,
        #{$operation-widget__news-details-title\color}: $color--cello,
        #{$operation-widget__news-details-header\border-color}: rgba($color--concrete, 0.03),
        #{$operation-widget__tool\color}: $color--ship-cove,
        #{$operation-widget__tool\bg-color}: $color--bunker,
        #{$operation-widget__tool--active\color}: $color--white,
        #{$operation-widget__tool--active\bg-color}: $color--tangaroa,
        #{$custom-scrollbar__thumb-vertical\bg-color}: $color--cello,
        #{$custom-scrollbar__thumb-vertical-hover\bg-color}: $color--ship-cove,
        #{$header-state__item-description\color}: $color--ship-cove,
        #{$header-state__item-value\color}: $color--white,
        #{$symbols-widget__favorite-icon\fill}: $color--ship-cove,
        #{$symbols-widget__favorite-icon--active\fill}: $color--white,
        #{$symbols-widget__favorite-icon--hover\fill}: $color--white,
        #{$symbols-widget__details-favorite-icon\fill}: $color--cello,
        #{$symbols-widget__details-favorite-icon-active\fill}: $color--cinnabar,
        #{$financial-operations-form__title\color}: $color--ship-cove,
        #{$financial-operations-form__submit-btn\color}: $color--white,
        #{$financial-operations-form__submit-btn\bg-color}: $color--madison,
        #{$financial-operations-form__submit-btn--hover\bg-color}: $color--mirage,
        #{$variation-group\bg-color}: $color--bunker,
        #{$variation-group\color}: $color--ship-cove,
        #{$variation-group__item--selected\bg-color}: $color--madison,
        #{$variation-group__item--selected\color}: $color--ship-cove,
        #{$date-time--color\color}: $color--ship-cove,
        #{$state-account-dropdown-content\bg-color}: $color--tangaroa
    ),
    light: (
        #{$app-error\color}: $color--ship-cove,
        #{$app-error__message\color}: $color--cinnabar,
        #{$app-error__return\color}: $color--cello,
        #{$auth-error-modal__overlay\bg-color}: $color--mirage,
        #{$auth-error-modal__content\bg-color}: $color--white,
        #{$auth-error-modal__message\color}: $color--cinnabar,
        #{$auth-error-modal__close-btn\fill}: $color--cello,
        #{$content-preloader__dot\bg}: $color--madison,
        #{$input__label\color}: $color--ship-cove,
        #{$input__elem\border-color}: $color--ship-cove,
        #{$input__elem\color}: $color--bunker,
        #{$input__elem--hover\border-color}: $color--bunker,
        #{$input__elem--focus\bg-color}: $color--zircon,
        #{$input__elem--error\color}: $color--cinnabar,
        #{$input__elem--error\bg-color}: $color--zircon,
        #{$input__elem--error\border-color}: $color--cinnabar,
        #{$input__password-visibility-toggle\fill}: $color--ship-cove,
        #{$select\color}: $color--bunker,
        #{$select\bg-color}: $color--seashell,
        #{$select--focus\bg-color}: $color--solitude,
        #{$select\border-color}: $color--seashell,
        #{$select--hover\border-color}: $color--solitude,
        #{$select\placeholder-color}: $color--ship-cove,
        #{$select\control-color}: $color--ship-cove,
        #{$select--error\color}: $color--cinnabar,
        #{$select--error\bg-color}: $color--zircon,
        #{$select--error\border-color}: $color--cinnabar,
        #{$select-dropdown\color}: $color--cello,
        #{$select-dropdown\bg-color}: $color--white-smoke,
        #{$select-dropdown-item--focus\color}: $color--bunker,
        #{$select-dropdown-item--focus\bg-color}: $color--mystic,
        #{$select-dropdown-item--selected\color}: $color--bunker,
        #{$select-dropdown-item--selected\bg-color}: $color--solitude,
        #{$lang-switcher\color}: $color--cello,
        #{$lang-switcher__dropdown\bg-color}: $color--mystic,
        #{$lang-switcher__btn--hover\bg-color}: $color--rock-blue,
        #{$lang-switcher__btn--hover\color}: $color--white,
        #{$login-form__btn\color}: $color--white,
        #{$login-form__btn--demo\bg-color}: $color--ship-cove,
        #{$login-form__btn--submit\bg-color}: $linear-gradient--malachite,
        #{$login-form__btn--disabled\bg-color}: $color--alto,
        #{$login-form__btn--disabled\color}: $color--white,
        #{$login-form__grecaptcha-note\color}: $color--ship-cove,
        #{$login-form__grecaptcha-note-link\color}: $color--cello,
        #{$login-screen__title\color}: $color--cello,
        #{$main-logo\color}: $color--cello,
        #{$main-menu\bg-color}: $color--seashell,
        #{$main-menu__company-name\color}: $color--cinnabar,
        #{$main-menu__close-btn\fill}: $color--ship-cove,
        #{$main-menu\border-color}: rgba($color--rock-blue, 0.3),
        #{$main-menu__section-label\color}: $color--rock-blue,
        #{$main-menu__section-label\bg-color}: $color--seashell,
        #{$main-menu__link-icon\fill}: $color--rock-blue,
        #{$main-menu__link-text\color}: $color--cello,
        #{$main-menu__footer-inner\color}: $color--rock-blue,
        #{$main-menu-toggle\color}: $color--ship-cove,
        #{$main-menu__body--after\bg-color}: rgba($color--rock-blue, 0.3),
        #{$main-menu__link--active\bg-color}: $color--mystic,
        #{$main-menu__link--hover\bg-color}: $color--rock-blue,
        #{$main-menu__link-text--hover\color}: $color--white,
        #{$main-menu__link-text--active\color}: $color--madison,
        #{$main-menu__link-icon--hover\fill}: $color--white,
        #{$main-menu__link-icon--active\fill}: $color--madison,
        #{$main-menu___link-icon-arrow\fill}: $color--madison,
        #{$main-menu___link-icon-arrow--hover\fill}: $color--white,
        #{$main-menu___link-icon-arrow--active\fill}: $color--madison,
        #{$mode-tubmler\color}: $color--ship-cove,
        #{$symbols-widget__insurance-tumbler\bg-color}: $color--cinnabar,
        #{$symbols-widget__insurance-tumbler--checked\bg-color}: $color--camarone,
        #{$page\bg-color}: $color--white,
        #{$page\color}: $color--cello,
        #{$page__main\bg-color}: $color--solitude,
        #{$page\outline-color}: $color--cello,
        #{$page-area\bg-color}: $color--white,
        #{$page-header\bg-color}: $color--white,
        #{$page-header__logout-btn\color}: $color--madison,
        #{$unauthenticated-layout__logo\fill}: $color--cello,
        #{$user__self\color}: $color--madison,
        #{$user__connection\bg-color}: $color--ship-cove-l,
        #{$user__connection--normal\bg-color}: $color--apple,
        #{$user__connection--warn\bg-color}: $color--gold,
        #{$user__connection--problem\bg-color}: $color--cinnabar,
        #{$user__inner--short\bg-color}: $color--white-smoke,
        #{$time-widget\color}: $color--madison,
        #{$symbols-widget__header\border-color}: $color--solitude,
        #{$symbols-widget__footer\border-color}: $color--solitude,
        #{$symbols-widget__title\color}: $color--black-pearl,
        #{$tool\color}: $color--cello,
        #{$tool\bg-color}: $color--white-smoke,
        #{$tool--active\color}: $color--bunker,
        #{$tool--active\bg-color}: $color--mystic,
        #{$symbols-widget__groups-nav\color}: $color--cello,
        #{$symbols-widget__groups\bg-color}: $color--seashell,
        #{$symbols-widget__groups-nav\border-color}: $color--solitude,
        #{$symbols-widget__groups-name\color}: $color--cello,
        #{$symbols-widget__groups-name--active\color}: $color--bunker,
        #{$symbols-widget__groups-name--active\bg-color}: $color--white,
        #{$symbols-widget__table-head\color}: $color--ship-cove-l,
        #{$symbols-widget__table-head\border-color}: $color--solitude,
        #{$symbols-widget__table-td\color}: $color--madison,
        #{$symbols-widget__table-td--hover\color}: $color--bunker,
        #{$symbols-widget__table-td--hover\bg-color}: $color--lavender,
        #{$symbols-widget__table-td--up\color}: $color--salem,
        #{$symbols-widget__table-td--down\color}: $color--cinnabar,
        #{$symbols-widget__table-td--inactive\color}: $color--ship-cove-l,
        #{$symbols-widget__table-gag\color}: rgba($color--ship-cove-l, 0.4),
        #{$symbols-widget__details-title\color}: $color--madison,
        #{$symbols-widget__details-price-info-value\color}: $color--cello,
        #{$symbols-widget__details-price-info-value--up\color}: $color--salem,
        #{$symbols-widget__details-price-info-value--down\color}: $color--cinnabar,
        #{$symbols-widget__details-price-info-label\color}: $color--cello,
        #{$symbols-widget__details-body\color}: $color--cello,
        #{$symbols-widget__details-spread\color}: $color--cello,
        #{$symbols-widget__details-action\color}: $color--white,
        #{$symbols-widget__details-action--buy\bg-color}: $color--salem,
        #{$symbols-widget__details-action--sell\bg-color}: $color--cinnabar,
        #{$symbols-widget__details-action--buy-hover\bg-color}: $color--camarone,
        #{$symbols-widget__details-action--sell-hover\bg-color}: $color--tia-maria,
        #{$symbols-widget__details-action--disabled\bg-color}: $color--white-smoke,
        #{$symbols-widget__details-action--disabled\color}: rgba($color--cello, 0.35),
        #{$symbols-widget__details-volume-input\color}: $color--mirage,
        #{$symbols-widget__details-volume-input-placeholder\color}: $color--ship-cove-l,
        #{$symbols-widget__details-volume-input\bg-color}: $color--white,
        #{$symbols-widget__details-volume-input\border-color}: $color--spindle,
        #{$symbols-widget__details-volume-input--order\border-color}: $color--ship-cove-l,
        #{$symbols-widget__details-volume-input--focus\bg-color}: $color--white,
        #{$symbols-widget__details-volume-input--focus\border-color}: $color--ship-cove-l,
        #{$symbols-widget__details-star\fill}: $color--alto,
        #{$symbols-widget__details-star--active\fill}: $color--cinnabar,
        #{$symbols-widget__details-chart-icon\fill}: $color--alto,
        #{$symbols-widget__details-chart-icon--active\fill}: $color--salem,
        #{$symbols-widget__details-volume-input-btn--minus\bg-color}: $color--tomato,
        #{$symbols-widget__details-volume-input-btn--plus\bg-color}: $color--salad,
        #{$symbols-widget__details-volume-input-btn\color}: $color--white,
        #{$symbols-widget__details-volume-input-btn--active\border-color}: $color--ship-cove-l,
        #{$symbols-widget__search-close\fill}: $color--ship-cove-l,
        #{$symbols-widget__search-close--hover\fill}: $color--cello,
        #{$symbols-widget__search-input\color}: $color--bunker,
        #{$symbols-widget__search-input\bg-color}: $color--lavender,
        #{$symbols-widget__search-input\border-color}: $color--mystic,
        #{$symbols-widget__search-input-placeholder\color}: $color--ship-cove-l,
        #{$symbols-widget__search-dropdown\color}: $color--cello,
        #{$symbols-widget__search-dropdown\bg-color}: $color--mystic,
        #{$symbols-widget__search-dropdown-item--active\color}: $color--white,
        #{$symbols-widget__search-dropdown-item--active\bg-color}: $color--rock-blue,
        #{$symbols-widget__list-placeholder\fill}: $color--ship-cove-l,
        #{$symbols-widget__details-bet-delimiter\color}: $color--ship-cove-l,
        #{$symbols-widget__details-bet-delimiter\border-color}: $color--solitude,
        #{$symbols-widget__decorated-value\bg-color}: $color--white-smoke,
        #{$symbols-widget__decorated-value\color}: $color--mirage,
        #{$chart-widget__tabs\bg-color}: $color--seashell,
        #{$chart-widget__tabs-nav\color}: $color--cello,
        #{$chart-widget__tabs-nav\bg-color}: $color--seashell,
        #{$chart-widget__tabs-nav\border-color}: $color--solitude,
        #{$chart-widget__tabs-name\color}: $color--cello,
        #{$chart-widget__tabs-name--hover\color}: $color--bunker,
        #{$chart-widget__tabs-name--active\color}: $color--bunker,
        #{$chart-widget__tabs-name--active\bg-color}: $color--white,
        #{$chart-widget__tabs-name--active\border-color}: $color--solitude,
        #{$chart-widget__tabs-close\fill}: $color--ship-cove-l,
        #{$chart-widget__new-tab\fill}: $color--ship-cove-l,
        #{$chart-widget__new-tab\bg-color}: $color--white,
        #{$chart-widget__tools\border-color}: $color--solitude,
        #{$chart-widget__tools-drag\fill}: $color--ship-cove-l,
        #{$chart-widget__tool\color}: $color--cello,
        #{$chart-widget__tool\bg-color}: $color--white-smoke,
        #{$chart-widget__tool--active\color}: $color--bunker,
        #{$chart-widget__tool--active\bg-color}: $color--mystic,
        #{$chart-widget__dropdown-content\bg-color}: $color--mystic,
        #{$chart-widget__dropdown-btn\color}: $color--cello,
        #{$chart-widget__dropdown-btn--active\color}: $color--white,
        #{$chart-widget__dropdown-btn--active\bg-color}: $color--rock-blue,
        #{$chart-widget__dropdown-btn--hover\bg-color}: $color--rock-blue,
        #{$chart-widget__dropdown-btn--hover\color}: $color--white,
        #{$chart-widget__search-input\color}: $color--bunker,
        #{$chart-widget__search-input\bg-color}: $color--zircon,
        #{$chart-widget__search-input\border-color}: $color--bunker,
        #{$chart-widget__search-input-placeholder\color}: $color--ship-cove-l,
        #{$chart-widget__chart-placeholder\fill}: $color--ship-cove-l,
        #{$operation-widget__tabs-btn\color}: $color--cello,
        #{$operation-widget__tabs-btn--active\color}: $color--bunker,
        #{$operation-widget__tabs-nav\color}: $color--cello,
        #{$operation-widget__tabs-nav\bg-color}: $color--seashell,
        #{$operation-widget__tabs-nav\border-color}: $color--solitude,
        #{$operation-widget__tabs-list\bg-color}: $color--seashell,
        #{$operation-widget__tabs-item--active\bg-color}: $color--white,
        #{$operation-widget__tabs-item--not-active-hover\color}: $color--bunker,
        #{$operation-widget__tabs-item--has-notification\bg-color}: $color--cinnabar,
        #{$operation-widget__table-head-cell\color}: $color--ship-cove-l,
        #{$operation-widget__table-head\border-color}: $color--solitude,
        #{$operation-widget__table-cell\color}: $color--madison,
        #{$operation-widget__table-cell-hover\bg-color}: $color--lavender,
        #{$operation-widget__table-cell-hover\color}: $color--bunker,
        #{$operation-widget__table-cell--inactive\color}: $color--ship-cove-l,
        #{$operation-widget__table-btn\color}: $color--white,
        #{$operation-widget__table-btn\bg-color}: $color--ship-cove-l,
        #{$operation-widget__table-btn-hover\bg-color}: $color--cello,
        #{$operation-widget__table-btn--light\color}: $color--cello,
        #{$operation-widget__table-btn--light\bg-color}: $color--white-smoke,
        #{$operation-widget__table-btn-hover--light\bg-color}: $color--mystic,
        #{$operation-widget__table-cell--profit\color}: $color--salem,
        #{$operation-widget__table-cell--loss\color}: $color--cinnabar,
        #{$operation-widget__table-cell--percent\color}: $color--ship-cove-l,
        #{$operation-widget__table-error\color}: $color--cinnabar,
        #{$custom-scrollbar__thumb-vertical\bg-color}: $color--spindle,
        #{$custom-scrollbar__thumb-vertical-hover\bg-color}: $color--echo-blue,
        #{$operation-widget__news-details-title\color}: $color--ship-cove-l,
        #{$operation-widget__news-details-header\border-color}: $color--solitude,
        #{$operation-widget__tool\color}: $color--cello,
        #{$operation-widget__tool\bg-color}: $color--white-smoke,
        #{$operation-widget__tool--active\color}: $color--bunker,
        #{$operation-widget__tool--active\bg-color}: $color--mystic,
        #{$header-state__item-description\color}: $color--ship-cove-l,
        #{$header-state__item-value\color}: $color--black-pearl,
        #{$symbols-widget__favorite-icon\fill}: $color--cello,
        #{$symbols-widget__favorite-icon--active\fill}: $color--tia-maria,
        #{$symbols-widget__favorite-icon--hover\fill}: $color--cinnabar,
        #{$symbols-widget__details-favorite-icon\fill}: $color--alto,
        #{$symbols-widget__details-favorite-icon-active\fill}: $color--cinnabar,
        #{$financial-operations-form__title\color}: $color--cello,
        #{$financial-operations-form__submit-btn\color}: $color--white,
        #{$financial-operations-form__submit-btn\bg-color}: $color--ship-cove-l,
        #{$financial-operations-form__submit-btn--hover\bg-color}: $color--cello,
        #{$variation-group\bg-color}: $color--mystic,
        #{$variation-group\color}: $color--cello,
        #{$variation-group__item--selected\bg-color}: $color--white,
        #{$variation-group__item--selected\color}: $color--cello,
        #{$date-time--color\color}: $color--cello,
        #{$state-account-dropdown-content\bg-color}: $color--white-smoke
    )
);

/// @param {String} $className ['page'] - Block className which theme modifiers will be added to, e.g. '.page--light'.
/// @param {Map} $themes [$themes] - Map of themes to use
/// @param {Boolean} $isPage [false] - used to modify the selector and should be set to `true` in case of styling the `.page` block itself.
@mixin themify($className: 'page', $themes: $themes, $isPage: false) {
    // Iterate over the themes
    @each $theme, $map in $themes {
        // Create a selector (e.g. `.page--light .block`)
        $selector: '.#{$className}--#{$theme} &';
        // Or if it's the `page` itself just append a theme modifier (e.g. `.page--light`)
        @if ($isPage) {
            $selector: '&--#{$theme}';
        }

        #{$selector} {
            // Set the theme variable with `!global` that will be used by the `themed` function in the @content section
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value
                    )
                ) !global;
            }
            // Output user content
            @content;
            // Unset the theme variable with '!global' so that it can't be used outside the mixin
            $theme-map: null !global;
        }
    }
}

/// Helper function that simplifies the access to the $theme-map variable
/// and should be used only in the `themify` mixin.
///
/// @param {String} $key - Property value of a theme (e.g. 'color': #fff)
///
/// {Map} $theme-map - global variable exposed only within the `themify` mixin
@function themed($key) {
    @return map-get($theme-map, $key);
}
