.custom-scrollbar {
    &__track-vertical {
        top: 3px;
        bottom: 3px;
        right: 4px;
        width: 5px !important;
        background-color: transparent;
    }

    &__thumb-vertical {
        width: 100%;
        border-radius: 3px;
        transition: background-color 0.2s ease-out;

        @include themify() {
            background-color: themed($custom-scrollbar__thumb-vertical\bg-color);
        }

        &:hover {
            cursor: pointer;
            @include themify() {
                background-color: themed($custom-scrollbar__thumb-vertical-hover\bg-color);
            }
        }
    }
}
