.main-menu-toggle {
    @include themify() {
        color: themed($main-menu-toggle\color);
    }

    &__icon {
        color: inherit;
        display: block;
        height: 16px;
        position: relative;
        width: 22px;

        &::before,
        &::after,
        > span {
            background-color: currentColor;
            height: 2px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &::before,
        &::after {
            content: '';
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }

        > span {
            top: 7px;
        }
    }
}
