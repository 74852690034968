$spacer: 7px;
$spacer-lg: 9px;

.page {
    font-family: $font-stack-base;
    font-size: $font--page;
    font-weight: $font--normal;
    height: 100%;
    line-height: $lh--base;
    margin: 0;
    min-width: $width--min;
    padding: 0;
    transition: background-color 0.4s ease, color 0.4s ease;

    // Remove dotted outline on input or button text in Firefox
    & *::-moz-focus-inner {
        border: none;
    }

    // Remove clear cross and reveal eye on input in IE11
    & *::-ms-clear,
    & *::-ms-reveal {
        display: none;
    }

    & *:focus {
        outline: none;
    }

    // Visually hiding Google reCaptcha badge
    .grecaptcha-badge {
        visibility: hidden;
        opacity: 0;
        position: absolute !important;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px !important;
        width: 1px !important;
        margin-top: -1px;
        margin-left: -1px;
        padding: 0;
        border: 0;
        transition: none !important;
        bottom: 0 !important;
        right: 0 !important;
        box-shadow: none !important;
    }

    @include themify($isPage: true) {
        background-color: themed($page\bg-color);
        color: themed($page\color);

        &.is-user-tabbing *:focus {
            outline: 1px dashed themed($page\outline-color);
        }
    }

    &__main {
        @include themify() {
            background-color: themed($page__main\bg-color);
            min-height: calc(100vh - #{$page-header-height});
        }
    }

    &__content {
        position: relative;
        padding-top: $spacer;
        padding-bottom: calc(#{$spacer} * 2);

        @include above($lg) {
            padding-top: $spacer-lg;
            padding-bottom: calc(#{$spacer-lg} * 2);
        }
    }

    &__status-line {
        position: absolute;
        bottom: 0;
        left: 0;
        height: calc(#{$spacer} * 2);
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        @include above($lg) {
            height: calc(#{$spacer-lg} * 2);
        }
    }

    &__app-error {
        height: calc(100vh - #{$page-header-height} - (#{$spacer} * 3)); // top + bottom spacer
        display: flex;
        align-items: center;
        justify-content: center;

        @include above($lg) {
            height: calc(100vh - #{$page-header-height} - (#{$spacer-lg} * 3)); // top + bottom spacer
        }
    }
}
