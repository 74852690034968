.page-header {
    height: $page-header-height;

    @include themify() {
        background-color: themed($page-header\bg-color);
    }

    &__inner {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
    }

    &__actions-section {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;

        &--left {
            margin-right: 40px;
        }

        &--right {
            margin-left: 12px;
        }
    }

    &__menu-toggle {
        margin-right: 26px;
    }

    &__content {
        height: 100%;
        width: 50%;
        flex-grow: 1;
        flex-shrink: 1;
    }

    &__user-wrap {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        margin-left: auto;
        padding-left: 26px;
    }

    &__logout-btn-wrap {
        margin-left: 25px;
    }

    &__logout-btn {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: $font--semi-bold;
        letter-spacing: 0.13px;

        @include themify() {
            color: themed($page-header__logout-btn\color);
        }
    }

    &__logout-icon-wrap {
        margin-right: 10px;
    }

    &__logout-icon {
        fill: currentColor;
    }

    &__logout-text {
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.14px;
    }

    &__login {
        display: none;

        @include above(960px) {
            display: block;
        }

        &-value {
            font-size: 13px;
            font-weight: $font--normal;
            line-height: 1.4;

            @include themify() {
                color: themed($header-state__item-description\color);
            }
        }
    }
}
