// Font families
// @type List
$font-stack-base: Segoe UI, Helvetica, Arial sans-serif !default;
$font-stack-extra: RobotoCondensed, Helvetica, Arial sans-serif !default;

// Font-sizes
// @type Length
$font--page: 13px !default;

// Font weights
// @type Length
$font--ultra-light: 100 !default;
$font--light: 200 !default; // or thin
$font--book: 300 !default; // or demi
$font--normal: 400 !default; // or regular
$font--medium: 500 !default;
$font--semi-bold: 600 !default; // or semi-bold
$font--bold: 700 !default;
$font--heavy: 800 !default; // or black/extra-bold
$font--fat: 900 !default; // or extra-black/poster/ultra-black

// Default line-height
// @type Length
$lh--base: 1.42857143; // 20/14

// Base grid width
// @type Length
$width--min: 768px !default;
$width--max: 1210px !default;
$min-height: 818px !default;

$page-header-height: 47px;

// Transitions
// @type Property Values
$transition--default: all 0.2s ease-out;

// Relative or absolute URL where all assets are served from
// @type String
// @example scss - When using a CDN
// $base-url: "http://cdn.example.com/assets/";
$base-url: './' !default;

// Breakpoints
$lg: 1280px;

$base-letter-spacing: 0.14px;
