.mode-tumbler {
    height: 22px;
    display: flex;
    align-items: center;

    @include themify() {
        color: themed($mode-tubmler\color);
    }

    &__description {
        font-size: 16px;
        line-height: 1;
        letter-spacing: 0.14px;
        margin-left: 24px;

        &:hover {
            cursor: pointer;
        }
    }
}
