.login-screen {
    &__inner {
        margin-left: auto;
        margin-right: auto;
        width: 350px;
    }

    &__header {
        display: flex;
        margin-bottom: 15px;
    }

    &__title {
        font-size: 16px;

        @include themify() {
            color: themed($login-screen__title\color);
        }
    }

    &__lang-switcher {
        margin-left: auto;
    }
}
