$width50percent: calc(50% - 2px);

.trade-button {
    width: 100%;
    flex-grow: 0;
    display: block;
    max-width: $width50percent;
    font-size: 15px;
    line-height: 20px;
    border-radius: 4px;
    font-weight: $font--semi-bold;

    & + & {
        margin-left: 4px;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__row {
        display: flex;
        justify-content: space-between;
    }

    &__text {
        @include overflow-ellipsis();
    }

    &__text,
    &__value {
        font-weight: $font--semi-bold;
        letter-spacing: $base-letter-spacing;
    }

    &__value {
        font-size: 19px;
    }

    &--sell {
        .trade-button__inner {
            align-items: flex-end;
        }
    }

    &__icon {
        fill: #fff;
        margin: 2px;

        &-inner {
            display: flex;
            align-items: center;
        }
    }

    &--buy[disabled],
    &--sell[disabled] {
        cursor: not-allowed;

        @include themify() {
            color: themed($symbols-widget__details-action--disabled\color);
            background: themed($symbols-widget__details-action--disabled\bg-color);
        }
    }

    &__header {
        position: relative;
        width: 56.9%;
        height: 100%;
        min-height: 28px;
        text-align: left;
        padding: 0 10px;
        border-radius: 4px 4px 0 0;
        display: flex;
        align-items: center;

        .trade-button--sell & {
            justify-content: flex-end;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            z-index: 10;
            width: 8px;
            height: 8px;
            border-radius: 50%;

            .trade-button--buy & {
                right: -8px;
                box-shadow: -4px 4px 0;
            }

            .trade-button--sell & {
                left: -8px;
                box-shadow: 4px 4px 0;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 34px;
        padding: 0 10px;
        border-radius: 0 4px 4px 4px;

        .trade-button--sell & {
            border-radius: 4px 0 4px 4px;
        }
    }

    @include themify() {
        color: themed($symbols-widget__details-action\color);
        &--green {
            .trade-button__footer,
            .trade-button__header {
                background: themed($symbols-widget__details-action--buy\bg-color);
            }

            .trade-button__header::before {
                color: themed($symbols-widget__details-action--buy\bg-color);
            }

            &:hover:enabled {
                .trade-button__footer,
                .trade-button__header {
                    background: themed($symbols-widget__details-action--buy-hover\bg-color);
                }

                .trade-button__header::before {
                    color: themed($symbols-widget__details-action--buy-hover\bg-color);
                }
            }
        }
    }

    @include themify() {
        color: themed($symbols-widget__details-action\color);

        &--red {
            .trade-button__footer,
            .trade-button__header {
                background: themed($symbols-widget__details-action--sell\bg-color);
            }

            .trade-button__header::before {
                color: themed($symbols-widget__details-action--sell\bg-color);
            }

            &:hover:enabled {
                .trade-button__footer,
                .trade-button__header {
                    background: themed($symbols-widget__details-action--sell-hover\bg-color);
                }

                .trade-button__header::before {
                    color: themed($symbols-widget__details-action--sell-hover\bg-color);
                }
            }
        }
    }

    &--disabled {
        cursor: not-allowed;

        .trade-button__footer,
        .trade-button__header {
            @include themify() {
                background: themed($symbols-widget__details-action--disabled\bg-color);
            }
        }

        .trade-button__header::before {
            @include themify() {
                color: themed($symbols-widget__details-action--disabled\bg-color);
            }
        }

        &:hover:enabled {
            .trade-button__footer,
            .trade-button__header {
                @include themify() {
                    background: themed($symbols-widget__details-action--disabled\bg-color);
                }
            }

            .trade-button__header::before {
                @include themify() {
                    color: themed($symbols-widget__details-action--disabled\bg-color);
                }
            }
        }
    }

    &__info-label {
        font-size: 10px;
        line-height: 15px;
        text-transform: uppercase;
        letter-spacing: $base-letter-spacing;
        opacity: 0.5;
    }

    &__accent-text {
        font-size: 23px;
    }
}
