.input-number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border: 1px solid;
    height: 25px;
    border-radius: 4px;
    padding: 0 0 0 8px;

    @include themify() {
        background-color: themed($symbols-widget__details-volume-input\bg-color);
        border-color: themed($symbols-widget__details-volume-input\border-color);

        &--focused {
            border-color: themed($symbols-widget__details-volume-input--focus\border-color);
        }
    }

    &__description {
        display: flex;
        align-items: center;
        padding-right: 3px;
    }

    &__label {
        @include themify() {
            color: themed($symbols-widget__details-volume-input\color);
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    &__hint {
        margin-right: 3px;
    }

    &__input {
        margin: 0;
        padding: 0;
        border: 0;
        flex: 1;
        text-align: right;
        font-size: 15px;
        line-height: 18px;
        font-family: $font-stack-extra;
        font-weight: $font--normal;
        letter-spacing: 0.6px;
        min-width: 0;

        @include themify() {
            color: themed($symbols-widget__details-volume-input\color);
        }

        &,
        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    &__handler-wrap {
        border-left: 0;
    }

    &__handler {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        background-color: transparent;
        font-size: 0;
        padding: 0;
        width: 25px;
        height: 8px;

        @include themify() {
            color: themed($symbols-widget__details-volume-input\color);
        }

        &[disabled] {
            cursor: not-allowed;
        }

        &::after {
            content: '';
            right: 0;
            width: 0;
            height: 0;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
        }

        &--up {
            margin-bottom: 2px;

            &::after {
                border-top: 0 solid;
                border-bottom: 5px solid;

                @include themify() {
                    border-bottom-color: themed($symbols-widget__details-volume-input\color);
                }
            }

            &[disabled]::after {
                @include themify() {
                    border-bottom-color: themed($login-form__btn--disabled\bg-color);
                }
            }
        }

        &--down {
            &::after {
                top: auto;
                border-top: 5px solid;
                border-bottom: 0 solid;

                @include themify() {
                    border-top-color: themed($symbols-widget__details-volume-input\color);
                }
            }
            &[disabled]::after {
                @include themify() {
                    border-top-color: themed($login-form__btn--disabled\bg-color);
                }
            }
        }
    }

    &--lots {
        position: absolute;
        transform: translateX(-50%);
        width: 41.7143%;
        top: 0;
        left: 50%;
        z-index: 10;
    }
}
