.content-preloader {
    display: inline-flex;
    align-items: center;
    height: 11px;

    &--sm {
        height: 8px;
    }

    &__dot {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        animation: content-preloader 1.6s infinite ease-in-out both;

        @include themify() {
            background-color: themed($content-preloader__dot\bg);
        }

        &:nth-child(1) {
            animation-delay: -0.64s;
        }

        &:nth-child(2) {
            animation-delay: -0.32s;
        }

        &:nth-child(3) {
            animation-delay: -0.16s;
        }

        .content-preloader--sm & {
            width: 4px;
            height: 4px;
        }

        &:not(:first-child) {
            margin-left: 6px;

            .content-preloader--sm & {
                margin-left: 4px;
            }
        }
    }

    @keyframes content-preloader {
        0%,
        80%,
        100% {
            transform: scale(0);
        }

        40% {
            transform: scale(1.5);
        }
    }
}
