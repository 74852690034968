$header-height: 40px;
$groups-height: 27px;

.symbols-widget {
    height: 100%;

    .type-error {
        &.place-top::after {
            border-top-color: #e94d25;
        }
        &.place-bottom::after {
            border-bottom-color: #e94d25;
        }
        &.place-left::after {
            border-left-color: #e94d25;
        }
        &.place-right::after {
            border-right-color: #e94d25;
        }
    }

    &__header {
        height: $header-height;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        padding-left: 10px;
        padding-right: 10px;

        @include above($lg) {
            padding-left: 15px;
            padding-right: 15px;
        }

        @include themify() {
            box-shadow: inset 0 -1px themed($symbols-widget__header\border-color);
        }
    }

    &__title {
        font-size: 16px;
        font-weight: $font--semi-bold;
        line-height: 1;
        margin: 0;
        margin-left: auto;
        margin-right: -25px;

        @include themify() {
            border-bottom-color: themed($symbols-widget__title\color);
        }
    }

    &__tool {
        @include tool-colored();
    }

    &__search-trigger {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 4px;
    }

    &__return {
        margin-right: auto;
        height: 25px;
        display: flex;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 4px;
    }

    &__return-text {
        font-size: 13px;
        line-height: 1;
        display: inline-block;
        height: 14px;
    }

    &__return-icon {
        margin-right: 6px;
        fill: currentColor;
    }

    &__body {
        height: calc(100% - #{$header-height});
        display: flex;
        flex-direction: column;
    }

    &__list {
        height: 100%;
        flex: 1 0;
        display: flex;
        flex-direction: column;
    }

    &__list-preloader {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__empty-placeholder {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__empty-placeholder-icon {
        width: 40%;
        height: auto;

        @include themify() {
            fill: rgba(themed($symbols-widget__list-placeholder\fill), 0.7);
        }
    }

    &__groups {
        height: $groups-height;
        display: flex;

        @include themify() {
            background-color: themed($symbols-widget__groups\bg-color);
        }
    }

    &__groups-nav {
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 27px;
        width: 23px;

        @include themify() {
            color: themed($symbols-widget__groups-nav\color);
            border-color: themed($symbols-widget__groups-nav\border-color);
        }

        &--left {
            border-right: 1px solid;
        }

        &--right {
            border-left: 1px solid;
        }

        &:disabled > svg {
            opacity: 0.25;
        }
    }

    &__groups-nav-icon {
        fill: currentColor;
    }

    &__groups-list {
        flex: 1 0;
        overflow: hidden;
    }

    &__groups-list-inner {
        margin: 0; // ??
        padding: 0; // ??
    }

    &__groups-item {
        display: inline-block;
    }

    &__groups-name {
        font-size: 13px;
        line-height: 1;
        transition: color 0.2s ease-in, background-color 0.2s ease-in;
        height: 27px;
        padding-left: 10px;
        padding-right: 10px;

        @include themify() {
            color: themed($symbols-widget__groups-name\color);

            &:hover {
                color: themed($symbols-widget__groups-name--active\color);
            }

            &.is-active {
                cursor: default;
                color: themed($symbols-widget__groups-name--active\color);
                background-color: themed($symbols-widget__groups-name--active\bg-color);
            }
        }

        &.with-star {
            padding-left: 8px;
        }
    }

    &__table {
        height: calc(100% - #{$groups-height});
        display: flex;
        flex-direction: column;
    }

    &__table-head {
        border-bottom: 1px solid;

        @include themify() {
            border-bottom-color: themed($symbols-widget__table-head\border-color);
        }
    }

    &__table-head-elem {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
    }

    &__table-th {
        font-size: 10px;
        font-weight: $font--normal;
        line-height: 1;
        text-align: right;
        padding-top: 7px;
        padding-bottom: 5px;

        @include themify() {
            color: themed($symbols-widget__table-head\color);
        }

        @include below(851px) {
            font-size: 9px;
        }

        &:nth-child(1) {
            padding-left: 8px;
            text-align: left;
            width: 25%;

            @include above($lg) {
                padding-left: 15px;
            }

            @include below(1024px) {
                width: 30%;
            }
        }

        &:nth-child(2) {
            width: 22%;

            @include below(1024px) {
                width: 25%;
            }
        }

        &:nth-child(3) {
            width: 22%;

            @include below(1024px) {
                width: 25%;
            }
        }

        &:nth-child(4) {
            width: 16%;

            @include below(1024px) {
                width: 20%;
                padding-right: 8px;
            }
        }

        &:nth-child(5) {
            padding-right: 8px;
            width: 15%;

            @include above($lg) {
                padding-right: 15px;
            }

            @include below(1024px) {
                display: none;
            }
        }
    }

    &__table-body {
        flex-grow: 1;
        height: 100%;
        overflow-y: auto;
    }

    &__table-body-elem {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
    }

    &__table-td {
        padding-top: 8px;
        padding-bottom: 6px;
        text-align: right;
        font-family: $font-stack-extra;
        font-size: 13px;
        font-weight: $font--light;
        line-height: 1;
        letter-spacing: -0.039px;
        white-space: nowrap;
        padding-right: 0;
        transition: color 0.2s ease-in, background-color 0.2s ease-in;

        @include themify() {
            color: themed($symbols-widget__table-td\color);
        }

        @include below(851px) {
            font-size: 11px;
        }

        &:nth-child(1) {
            width: 25%;
            font-family: $font-stack-base;
            font-size: 16px;
            font-weight: $font--normal;
            text-align: left;
            padding-left: 8px;

            @include above($lg) {
                padding-left: 15px;
            }

            @include below(1024px) {
                width: 30%;
            }

            @include below(851px) {
                font-size: 13px;
            }
        }

        &:nth-child(2) {
            width: 22%;

            @include below(1024px) {
                width: 25%;
            }
        }

        &:nth-child(3) {
            width: 22%;
            @include below(1024px) {
                width: 25%;
            }
        }

        &:nth-child(4) {
            width: 16%;
            padding-right: 5px;

            @include below(1024px) {
                width: 20%;
                padding-right: 10px;
            }
        }

        &:nth-child(5) {
            width: 15%;
            padding-right: 10px;

            @include above($lg) {
                padding-right: 15px;
            }

            @include below(1024px) {
                display: none;
            }
        }

        &--colorable.is-up {
            @include themify() {
                color: themed($symbols-widget__table-td--up\color);
            }
        }

        &--colorable.is-down {
            @include themify() {
                color: themed($symbols-widget__table-td--down\color);
            }
        }

        .symbols-widget__table-body-row--inactive & {
            @include themify() {
                color: themed($symbols-widget__table-td--inactive\color);
            }
        }
    }

    &__table-body-row:hover &__table-td {
        cursor: pointer;

        @include themify() {
            color: themed($symbols-widget__table-td--hover\color);
            background-color: themed($symbols-widget__table-td--hover\bg-color);
        }
    }

    &__symbol-name {
        &[data-dragable] {
            font-family: $font-stack-base;
            font-size: 16px;
            font-weight: $font--bold;
            text-transform: uppercase;
            text-align: left;
            border-radius: 3px;
            padding: 2px 8px;
            cursor: grabbing;

            & span {
                cursor: grabbing;
            }

            @include themify() {
                color: themed($symbols-widget__table-td--hover\color);
                background-color: themed($symbols-widget__table-td--hover\bg-color);
            }
        }
    }

    &__details {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 5px 0;
        position: relative;

        @include above(915px) {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @include above($lg) {
            padding-top: 15px;
        }
    }

    &__details-header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;

        @include above($lg) {
            margin-bottom: 9px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__details-title {
        font-size: 22px;
        font-weight: $font--semi-bold;
        line-height: 1;

        @include themify() {
            color: themed($symbols-widget__details-title\color);
        }

        @include below(960px) {
            font-size: 16px;
        }
    }

    &__details-favorites {
        cursor: pointer;
        margin-left: 11px;
        width: 20px;
        height: 20px;

        @include below(1024px) {
            margin-left: 8px;
        }

        @include below(960px) {
            margin-left: 3px;
            padding-top: 3px;
            svg {
                width: 16px;
                height: 16px;
            }
        }

        @include themify() {
            color: themed($symbols-widget__details-star\fill);
        }

        &.is-active {
            @include themify() {
                color: themed($symbols-widget__details-star--active\fill);
            }
        }
    }

    &__details-chart-btn {
        cursor: pointer;
        margin-left: 8px;
        width: 20px;
        height: 20px;

        @include below(960px) {
            padding-top: 3px;
            margin-left: 0;
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    &__details-chart-icon {
        vertical-align: baseline;

        @include themify() {
            fill: themed($symbols-widget__details-chart-icon\fill);
        }

        .is-active & {
            @include themify() {
                fill: themed($symbols-widget__details-chart-icon--active\fill);
            }
        }
    }

    &__details-price-info {
        display: flex;
        line-height: 1;
        margin-left: auto;
    }

    &__details-price-info-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        & + & {
            margin-left: 8px;
        }
    }

    &__details-price-info-label {
        display: block;
        font-size: 10px;
        font-weight: $font--normal;
        margin-bottom: 3px;

        @include themify() {
            color: themed($symbols-widget__details-price-info-label\color);
        }

        @include above(960px) {
            font-size: 11px;
        }

        @include above($lg) {
            font-size: 12px;
            margin-bottom: 5px;
        }
    }

    &__details-price-info-value {
        font-family: $font-stack-extra;
        font-size: 12px;
        font-weight: $font--light;

        @include above(851px) {
            font-size: 13px;
        }

        @include above(960px) {
            font-size: 14px;
        }

        @include themify() {
            color: themed($symbols-widget__details-price-info-value\color);
        }

        &--colorable.is-up {
            @include themify() {
                color: themed($symbols-widget__details-price-info-value--up\color);
            }
        }

        &--colorable.is-down {
            @include themify() {
                color: themed($symbols-widget__details-price-info-value--down\color);
            }
        }
    }

    &__details-body {
        @include themify() {
            color: themed($symbols-widget__details-body\color);
        }
    }

    &__details-body-inner {
        padding-left: 10px;
        padding-right: 10px;

        @include above($lg) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__details-form-type-select-container {
        width: 100%;
    }

    &__details-footer {
        position: absolute;
        width: 100%;
        top: calc(100% - 60px);
        left: 0;
        padding: 10px;
        border-top: 1px solid;

        @include above($lg) {
            padding-left: 15px;
            padding-right: 15px;
        }

        @include themify() {
            background-color: themed($page-area\bg-color);
            border-top-color: themed($symbols-widget__header\border-color);
        }
    }

    &__details-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }

    &__details-action {
        flex-grow: 0;
        flex-shrink: 1;
        display: block;
        font-size: 12px;
        line-height: 1.1;
        border-radius: 4px;
        height: 40px;
        padding: 8px;
        width: 100%;

        @include above(851px) {
            font-size: 14px;
        }

        @include above(960px) {
            font-size: 17px;
        }

        & + & {
            margin-left: 10px;

            @include above(960px) {
                margin-left: 20px;
            }
        }

        &--buy[disabled],
        &--sell[disabled] {
            cursor: not-allowed;

            @include themify() {
                color: themed($symbols-widget__details-action--disabled\color);
                background: themed($symbols-widget__details-action--disabled\bg-color);
            }
        }

        @include themify() {
            color: themed($symbols-widget__details-action\color);

            &--buy {
                background: themed($symbols-widget__details-action--buy\bg-color);

                &:hover:enabled {
                    background: themed($symbols-widget__details-action--buy-hover\bg-color);
                }
            }

            &--sell {
                background: themed($symbols-widget__details-action--sell\bg-color);

                &:hover:enabled {
                    background: themed($symbols-widget__details-action--sell-hover\bg-color);
                }
            }
        }
    }

    &__details-action-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__details-action-values {
        flex-grow: 1;
        display: flex;
        align-items: baseline;
        justify-content: center;
    }

    &__details-action-text {
        font-weight: $font--semi-bold;
        padding-right: 10px;
    }

    &__details-action-value {
        font-family: $font-stack-extra;
    }

    &__details-action-commission {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 10px;

        @include above(851px) {
            font-size: 12px;
        }

        @include above(960px) {
            font-size: 14px;
        }

        &-row {
            width: 100%;
            display: flex;
            align-items: center;
            min-height: 14px;

            @include above(851px) {
                font-size: 12px;
                min-height: 19px;
            }
        }

        &-text {
            padding-right: 10px;
            margin-right: auto;
        }

        &-value {
            min-width: 36px;
            text-align: right;

            @include above(851px) {
                min-width: 40px;
            }

            @include above(960px) {
                min-width: 50px;
            }
        }
    }

    &__details-volume {
        display: flex;
        align-items: center;
    }

    &__details-volume-input {
        display: block;
        border: 1px solid;
        border-radius: 4px;
        font-family: $font-stack-extra;
        font-size: 16px;
        font-weight: $font--normal;
        letter-spacing: 0.6px;
        height: 31px;
        line-height: 1;
        padding: 7px 10px 6px;
        text-align: left;
        width: 100px;
        margin-left: 5px;
        margin-right: 5px;

        @include below($lg) {
            height: 24px;
        }

        @include themify() {
            color: themed($symbols-widget__details-volume-input\color);
            background-color: themed($symbols-widget__details-volume-input\bg-color);
            border-color: themed($symbols-widget__details-volume-input\border-color);
            &:focus {
                background-color: themed($symbols-widget__details-volume-input--focus\bg-color);
                border-color: themed($symbols-widget__details-volume-input--focus\border-color);
            }
        }

        @include placeholder() {
            font-weight: $font--light;

            @include themify() {
                color: themed($symbols-widget__details-volume-input-placeholder\color);
            }
        }
    }

    &__details-volume-btn {
        width: 31px;
        height: 31px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include below($lg) {
            height: 24px;
            width: 24px;
        }
    }

    &__search {
        z-index: 2;
        position: absolute;
        width: calc(100% - 20px);

        @include above($lg) {
            width: calc(100% - 30px);
        }
    }

    &__search-input {
        display: block;
        border: 1px solid;
        border-radius: 4px;
        font-family: $font-stack-base;
        font-size: 16px;
        font-weight: $font--normal;
        height: 25px;
        line-height: 1;
        padding: 4px 25px 4px 10px;
        text-align: left;
        width: 100%;
        margin: 0;
        position: relative;
        z-index: 1;

        @include themify() {
            color: themed($symbols-widget__search-input\color);
            background-color: themed($symbols-widget__search-input\bg-color);
            border-color: themed($symbols-widget__search-input\border-color);
        }

        @include placeholder() {
            font-weight: $font--light;
            font-size: 14px;
            line-height: 1.2;

            @include themify() {
                color: themed($symbols-widget__search-input-placeholder\color);
            }
        }
    }

    &__search-close {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        z-index: 1;

        @include themify() {
            fill: themed($symbols-widget__search-close\fill);
            &:hover {
                fill: themed($symbols-widget__search-close--hover\fill);
            }
        }
    }

    &__search-close-icon {
        vertical-align: top;
    }

    &__search-dropdown {
        border-radius: 4px;
        position: absolute;
        width: 100%;
        top: 18px;
        right: 0;
        padding-top: 10px;
        padding-bottom: 7px;

        @include themify() {
            color: themed($symbols-widget__search-dropdown\color);
            background-color: themed($symbols-widget__search-dropdown\bg-color);
        }
    }

    &__search-dropdown-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__search-dropdown-item {
        font-size: 16px;
        line-height: 1;
        padding: 6px 10px 6px;
        transition: color 0.1s ease-in, background-color 0.1s ease-in;

        &--active {
            cursor: pointer;

            @include themify {
                color: themed($symbols-widget__search-dropdown-item--active\color);
                background-color: themed($symbols-widget__search-dropdown-item--active\bg-color);
            }
        }
    }

    &__favorite-icon {
        vertical-align: top;
        margin-right: 6px;

        @include themify() {
            fill: themed($symbols-widget__favorite-icon\fill);
        }

        .symbols-widget__groups-name.is-active & {
            @include themify() {
                fill: themed($symbols-widget__favorite-icon--active\fill);
            }
        }

        .symbols-widget__groups-name.with-star:not(.is-active):hover & {
            @include themify() {
                fill: themed($symbols-widget__favorite-icon--hover\fill);
            }
        }
    }

    &__details-favorite-icon {
        vertical-align: baseline;

        @include themify() {
            fill: themed($symbols-widget__details-favorite-icon\fill);
        }

        .symbols-widget__details-favorites.is-active & {
            @include themify() {
                fill: themed($symbols-widget__details-favorite-icon-active\fill);
            }
        }
    }

    .symbols-widget__insurance {
        height: 96px;
        padding-top: 36px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        // margin-bottom: 5px;

        @include below($lg) {
            height: 72px;
            padding-top: 28px;
        }

        &-select-container {
            display: block;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 1;

            @include below($lg) {
                .select__control {
                    height: 24px;
                    font-size: 14px;
                    line-height: 1;
                }

                .select__value-container {
                    padding: 2px 0 2px 7px;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.02em;
                }

                .select__placeholder {
                    font-size: 13px;
                    line-height: 16px;
                }

                .select__menu {
                    top: 24px;
                    margin: 0;
                }

                .select__option,
                .select__menu-notice {
                    padding: 5px 5px 4px 7px;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.02em;
                }
            }
        }

        &-select-scroll {
            .custom-scrollbar__track-vertical {
                right: 1px;
            }

            .custom-scrollbar__thumb-vertical {
                z-index: 10;
                opacity: 0.65;
            }
        }

        &-descriptions-container {
            padding-left: 12px;

            @include below($lg) {
                padding-left: 7px;
            }
        }

        &-description {
            height: 32px;
            line-height: 32px;

            @include below($lg) {
                height: 24px;
                line-height: 24px;
            }
        }
    }

    .symbols-widget__form {
        font-family: $font-stack-base;
        font-weight: $font--book;
        margin-bottom: 10px;

        @include above(1024px) {
            padding-top: 10px;
        }

        @include above($lg) {
            margin-bottom: 0;
        }

        @include themify() {
            color: themed($symbols-widget__details-body\color);
        }

        & .type-error {
            background-color: #e94d25;
        }

        &-row {
            margin-bottom: 5px;
            display: flex;
            justify-content: flex-start;

            @include above($lg) {
                margin-bottom: 10px;
            }
        }

        &-delimiter {
            display: flex;
            justify-content: flex-start;
            margin-top: -1px;
            margin-bottom: 5px;
            padding-bottom: 4px;
            font-size: 13px;

            @include above($lg) {
                margin-bottom: 10px;
                font-size: 16px;
            }

            line-height: 1;
            font-weight: $font--normal;
            border-bottom: 1px solid;

            @include themify() {
                color: themed($symbols-widget__details-bet-delimiter\color);
            }

            @include themify() {
                border-bottom-color: themed($symbols-widget__details-bet-delimiter\border-color);
            }
        }

        &-col {
            width: 50%;
            margin: 0;
            flex-grow: 0;
            flex-shrink: 0;
            flex-wrap: nowrap;
            display: flex;

            & + & {
                padding-left: 5px;
                @include above(960px) {
                    padding-left: 10px;
                }
            }
        }
    }

    &__details--on-bets {
        .symbols-widget__details-body {
            font-family: $font-stack-base;
            font-weight: $font--book;
            font-size: 13px;
            line-height: 19px;
            height: calc(100% - 36px);
            padding-bottom: 50px;

            @include above(915px) {
                height: calc(100% - 30px);
            }

            @include above(1024px) {
                padding-top: 5px;
            }

            @include above($lg) {
                font-size: 16px;
                line-height: 25px;
                padding-top: 9px;
                height: calc(100% - 40px);
            }
        }

        .symbols-widget__spread {
            font-size: 13px;
            line-height: 1;
            font-weight: $font--semi-bold;

            @include above($lg) {
                font-size: 16px;
                font-weight: $font--normal;
            }

            @include themify() {
                color: themed($symbols-widget__details-spread\color);
            }
        }

        .symbols-widget__spread-value {
            font-family: $font-stack-extra;
            margin-left: 8px;
        }

        .symbols-widget__checkbox {
            margin: 0 5px 0 0;
            opacity: 0;
            z-index: 1;
            cursor: pointer;
            position: relative;

            & + label {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    top: 5px;
                    left: -19px;
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    border-radius: 3px;

                    @include themify() {
                        background-color: themed($tool\bg-color);
                    }
                }
            }

            &:focus + label::before {
                @include themify() {
                    background-color: themed($tool--active\bg-color);
                }
            }

            &:active + label::before {
                @include themify() {
                    background-color: themed($tool\bg-color);
                }
            }

            &:checked + label::after {
                content: '';
                position: absolute;
                left: -15px;
                top: 5px;
                display: inline-block;
                width: 7px;
                height: 11px;
                transform: rotate(45deg);
                border-right: 1.5px solid;
                border-bottom: 1.5px solid;
                @include themify() {
                    border-color: themed($tool\color);
                }
            }
        }

        .symbols-widget__details-body-row {
            margin-top: 0;
            margin-bottom: 5px;

            @include above($lg) {
                margin-bottom: 10px;
            }

            &--with-elements {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
        }

        .symbols-widget__input {
            display: block;
            font-size: 14px;
            font-family: $font-stack-extra;
            font-weight: $font--normal;
            height: 24px;
            width: 100px;
            padding: 5px 8px 4px;
            line-height: 1;
            text-align: right;
            border-radius: 4px;
            border: 1px solid;

            @include above($lg) {
                font-size: 16px;
                letter-spacing: 0.6px;
                height: 31px;
                padding: 7px 10px 6px;
            }

            @include themify() {
                color: themed($symbols-widget__details-volume-input\color);
                background-color: themed($symbols-widget__details-volume-input\bg-color);
                border-color: themed($symbols-widget__details-volume-input\border-color);
                &:focus {
                    background-color: themed($symbols-widget__details-volume-input--focus\bg-color);
                    border-color: themed($symbols-widget__details-volume-input--focus\border-color);
                }
                &.hasError {
                    border-color: themed($symbols-widget__details-star--active\fill);
                }
            }

            @include placeholder() {
                font-weight: $font--light;

                @include themify() {
                    color: themed($symbols-widget__details-volume-input-placeholder\color);
                }
            }

            &--with-prepend {
                padding-left: 14px;
                @include above($lg) {
                    padding-left: 18px;
                }
            }
        }

        .symbols-widget__input-prepend {
            position: absolute;
            font-size: 12px;
            line-height: 1;
            font-weight: $font--bold;
            top: 50%;
            transform: translateY(-50%);
            left: 5px;

            @include above($lg) {
                font-size: 16px;
                left: 8px;
            }

            @include themify() {
                color: themed($symbols-widget__details-body\color);
                fill: themed($symbols-widget__details-body\color);
            }

            &--disabled {
                opacity: 0.5;
            }

            &--hidden {
                display: none;
            }
        }

        .symbols-widget__input-container {
            display: flex;
            align-items: center;
            position: relative;
            flex-wrap: nowrap;
            width: 100%;

            &--multiplier {
                align-items: baseline;
                margin-left: auto;

                @include above(960px) {
                    width: 170px;
                }

                @include above(1400px) {
                    width: 220px;
                }

                & .symbols-widget__input {
                    width: 55px;
                    @include above($lg) {
                        width: 35%;
                    }
                }
            }

            &--volume {
                margin-left: auto;

                @include above(960px) {
                    width: 170px;
                }

                @include above(1400px) {
                    width: 220px;
                }

                & .symbols-widget__input {
                    width: 100%;
                }
            }

            &--price {
                margin-left: auto;

                @include above(960px) {
                    width: 170px;
                }

                @include above(1400px) {
                    width: 220px;
                }

                & .symbols-widget__input {
                    width: 100%;
                }
            }

            &--profitLimit {
                margin-left: auto;
                @include above(960px) {
                    width: 80%;
                }

                @include above($lg) {
                    width: 140px;
                }

                & .symbols-widget__input {
                    width: 100%;
                }
            }

            &--lossLimit {
                margin-left: auto;
                @include above(960px) {
                    width: 80%;
                }

                @include above($lg) {
                    width: 140px;
                }

                & .symbols-widget__input {
                    width: 100%;
                }
            }
        }

        .symbols-widget__multiplier-amount {
            font-size: 12px;
            padding-left: 4px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            @include above(1400px) {
                font-size: 14px;
            }

            &--hidden {
                display: none;
            }
        }

        .symbols-widget__tumbler-wrapper {
            display: inline-block;
            flex-grow: 1;
            &--hidden {
                display: none;
            }
        }

        .symbols-widget__tumbler {
            margin-left: 5px;
            margin-right: 5px;
            white-space: nowrap;
            border-bottom: 1px dashed;
            border-top: 1px solid transparent;
            cursor: pointer;
            @include themify() {
                border-bottom-color: themed($symbols-widget__details-body\color);
                color: themed($symbols-widget__details-body\color);
            }
            &:hover {
                @include themify() {
                    color: themed($input__elem\color);
                }
            }
        }

        .symbols-widget__actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
        }

        .symbols-widget__action {
            flex-grow: 0;
            flex-shrink: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 17px;
            line-height: 1.1;
            border-radius: 4px;
            height: 40px;
            padding: 8px;
            width: 100%;

            & + .symbols-widget__action {
                margin-left: 20px;
                @include below(960px) {
                    margin-left: 10px;
                }
            }

            &--buy[disabled],
            &--sell[disabled] {
                cursor: not-allowed;

                @include themify() {
                    color: themed($symbols-widget__details-action--disabled\color);
                    background: themed($symbols-widget__details-action--disabled\bg-color);
                }
            }

            @include themify() {
                color: themed($symbols-widget__details-action\color);
                fill: themed($symbols-widget__details-action\color);

                &--buy {
                    background: themed($symbols-widget__details-action--buy\bg-color);

                    &:hover:enabled {
                        background: themed($symbols-widget__details-action--buy-hover\bg-color);
                    }
                }

                &--sell {
                    background: themed($symbols-widget__details-action--sell\bg-color);

                    &:hover:enabled {
                        background: themed($symbols-widget__details-action--sell-hover\bg-color);
                    }
                }
            }
        }

        .symbols-widget__action-text {
            font-weight: $font--semi-bold;
            text-transform: uppercase;
            display: flex;
            flex-grow: 1;
            justify-content: center;
            margin-right: 6px;

            @include below($lg) {
                display: none;
            }
        }

        .symbols-widget__action-arrow {
            width: 14px;
            height: 20px;

            @include below($lg) {
                height: 18px;
                width: 26px;
            }
        }

        .symbols-widget__description {
            display: inline-block;
            margin-right: auto;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-right: 5px;
        }
    }

    &__details--on-trading {
        @include above($lg) {
            padding-top: 10px;
        }

        .symbols-widget__details-header {
            height: 24px;
            display: flex;
            align-items: flex-end;
            margin-top: 0;
            margin-bottom: 5px;
            justify-content: space-between;

            @include above($lg) {
                height: 33px;
            }
        }

        .symbols-widget__details-title {
            flex-shrink: 0;
            margin-right: 8px;
            font-size: 18px;
            font-weight: $font--semi-bold;
            line-height: 20px;
            letter-spacing: 0.14px;

            @include themify() {
                color: themed($symbols-widget__details-title\color);
            }

            @include above($lg) {
                font-size: 25px;
                line-height: 28px;
            }
        }

        .symbols-widget__details-price-info {
            flex-basis: 180px;
            flex-shrink: 1;
            display: flex;
            justify-content: space-between;

            @include above($lg) {
                flex-basis: 220px;
            }
        }

        .symbols-widget__details-price-info-item + .symbols-widget__details-price-info-item {
            margin-left: 2px;
        }

        .symbols-widget__details-price-info-label {
            font-family: $font-stack-extra;
            font-size: 10px;
            line-height: 11px;
            letter-spacing: 0.14px;
            font-weight: $font--book;
            margin-bottom: 1px;

            @include above($lg) {
                font-size: 11px;
                line-height: 13px;
                margin-bottom: 1px;
            }
        }

        .symbols-widget__details-price-info-value {
            font-family: $font-stack-extra;
            font-weight: $font--book;
            letter-spacing: 0.14px;
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 0;

            @include above($lg) {
                font-size: 13px;
                line-height: 15px;
            }
        }

        .symbols-widget__details-body {
            font-family: $font-stack-base;
            font-weight: $font--book;
            font-size: 13px;
            line-height: 19px;
            height: calc(100% - 28px); // change with btns
            padding-bottom: 50px; // change with btns

            @include above($lg) {
                height: calc(100% - 38px); // change with btns
            }
        }

        .symbols-widget__details-body-inner {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 10px;
        }

        .symbols-widget__details-body-row {
            margin-bottom: 0;
        }

        .symbols-widget__details-body-row + .symbols-widget__details-body-row {
            margin-top: 5px;

            @include above($lg) {
                margin-top: 8px;
            }
        }
    }

    &__table-gag {
        width: 60%;
        margin-left: auto;

        &--time {
            width: 90%;
        }

        &--changes {
            width: 70%;
        }

        hr {
            border: none;
            border-top: 1px dashed;

            @include themify() {
                border-color: themed($symbols-widget__table-gag\color);
            }
        }
    }
}
